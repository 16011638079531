import { ReviewModel, UserDiscountModel } from '../review/types'
import { UserData } from '../user/types'

export enum ListingActionTypes {
  SET_LISITNGS_DATA = '@@lisitng/SET_LISITNGS_DATA',
  SET_ALL_LISITNGS_DATA = '@@lisitng/SET_ALL_LISITNGS_DATA',
  SET_OTHERS_LISITNGS_DATA = '@@lisitng/SET_OTHERS_LISITNGS_DATA',
  SET_CLEAN_LOG_DATA = '@@lisitng/SET_CLEAN_LOG_DATA',
  FETCH_LISTING_DATA = '@@lisitng/FETCH_LISTING_DATA',
  UPDATE_LISTING_DATA = '@@lisitng/UPDATE_LISTING_DATA',
  CREATE_LISTING_DATA = '@@lisitng/CREATE_LISTING_DATA',
  FETCH_OTHERS_LISTING = '@@lisitng/FETCH_OTHERS_LISTING',
  UPDATE_ACTIVE_STATUS = '@@lisitng/UPDATE_ACTIVE_STATUS',
  SET_FAV_STATUS = '@@lisitng/SET_FAV_STATUS',
  FETCH_LISTING_TYPES = '@@lisitng/FETCH_LISTING_TYPES',
  SET_LISTING_TYPES = '@@lisitng/SET_LISTING_TYPES',
  SET_LISTING_POST = '@@lisitng/SET_LISTING_POST',
  DELETE_LISTING = '@@lisitng/DELETE_LISTING',
  EDIT_LISTING = '@@lisitng/EDIT_LISTING',
  FETCH_ALL_LISTINGS_FOR_CUSTOMER = '@@lisitng/FETCH_ALL_LISTINGS_FOR_CUSTOMER',
  FETCH_ALL_LISTINGS = '@@lisitng/FETCH_ALL_LISTINGS',
  FETCH_ALL_FAV_LISTINGS_FOR_CUSTOMER = '@@lisitng/FETCH_ALL_FAV_LISTINGS_FOR_CUSTOMER',
  CHECKOUT_LISTING = '@@lisitng/CHECKOUT_LISTING',
  CREATE_TRANSACTION = '@@lisitng/CREATE_TRANSACTION',
  CREATE_CLEAN_LOG = '@@lisitng/CREATE_CLEAN_LOG',
  FETCH_CLEAN_LOG = '@@lisitng/FETCH_CLEAN_LOG',
  SET_CLEAN_LOG_LOADING = '@@lisitng/SET_CLEAN_LOG_LOADING',
  FETCH_SELECTED_LISTING = '@@lisitng/FETCH_SELECTED_LISTING',
  SET_SELECTED_LISTING = '@@lisitng/SET_SELECTED_LISTING',
  IS_LISTING_UPDATED = '@@lisitng/IS_LISTING_UPDATED',
  FETCH_LISTING_POST = '@@lisitng/FETCH_LISTING_POST',
  UPLOAD_ID = '@@lisitng/UPLOAD_ID',
  IS_VALIDATION_COMPLETE = '@@lisitng/IS_VALIDATION_COMPLETE',
  SET_LOADING_PAYMENT_DATA = '@@listing/SET_LOADING_PAYMENT_DATA',
  CREATE_OR_UPDATE_CLEANLOG_STRUCTURE = '@@listing/CREATE_OR_UPDATE_CLEANLOG_STRUCTURE',
  FETCH_CLEANLOG_STRUCTURE = '@@listing/FETCH_CLEANLOG_STRUCTURE',
  SET_CLEANLOG_STRUCTURE = '@@listing/SET_CLEANLOG_STRUCTURE',
  UPDATE_SELECTED_LISTING_FIELD = '@@listing/UPDATE_SELECTED_LISTING_FIELD',
  SET_CREATING_LISTING_STATUS = '@@listing/SET_CREATING_LISTING_STATUS',
  FETCH_LISTING_USER_DATA = '@@listing/FETCH_LISTING_USER_DATA',
  SET_LISTING_USER_DATA = '@@listing/SET_LISTING_USER_DATA',
  VERIFY_ID_REQUEST = '@@listing/VERIFY_ID_REQUEST',
  SET_TRANSACTION_DATA = '@@listing/SET_TRANSACTION_DATA',
  RESOLVE_BROKEN_ITEMS = '@@listing/RESOLVE_BROKEN_ITEMS',
  EDIT_INCIDENT_REPORT_BY_BO = '@@listing/EDIT_INCIDENT_REPORT_BY_BO',
  GET_TEMPLATE = '@@listing/GET_TEMPLATE',
  SET_TEMPLATE = '@@listing/SET_TEMPLATE',
}

export interface CleanLogStructureModel {
  user_id: number
  cleanlog_structure: string
}

export interface ListingType {
  id: number
  name: string
  description: string
}

export interface DashboardDataModel {
  listing_id: number
  time_frame: string
  userTimezone: string
}

export interface ListingModel {
  title: string
  id: number
  list_type_id: number
  list_type_name?: string
  cleanlog_structure: string
  rule: string
  price: number
  preferred_price?: string
  charge_customer?: number
  free_use: boolean
  open_hour: string
  close_hour: string
  is_active: boolean
  location: string
  customers_today?: number
  earnings_today?: number
  total_customers?: number
  total_earning?: number
  review_count: string
  review_rating: string
  uploads: string
  qr_code: string
  mobile_number: string
  is_favorite?: boolean
  single_review?: ReviewModel
  instructions: string
  post_id: number
  valid_id_required: boolean
  user_id: number
  amenities: string
  user_discount?: UserDiscountModel
  is_blocked?: boolean
}

export interface ListingsModel {
  title: string
  id: number
  list_type_id: number
  list_type_name?: string
  amenities: string
  rule: string
  price: number
  preferred_price?: string
  charge_customer?: number
  free_use: boolean
  open_hour: Date
  close_hour: Date
  is_active: boolean
  location: string
  customers_today?: number
  earnings_today?: number
  total_customers?: number
  total_earning?: number
  review_count: string
  review_rating: string
  uploads: string
  qr_code: string
  mobile_number: string
  is_favorite?: boolean
  single_review?: ReviewModel
  instructions: string
  post_id: number
  valid_id_required: boolean

  user_discount?: UserDiscountModel
  is_blocked?: boolean
}

export interface ListingState {
  readonly listingData: ListingModel[]
  readonly active_count: number
  readonly listingbyOthers: ListingModel[]
  readonly cleaningLogListing: any
  readonly listingTypes: ListingType[]
  selectedListing?: ListingModel
  readonly loading: {
    listingData?: boolean
    paymentData?: boolean
    creatingListing?: boolean
    cleanLog?: boolean
  }
  readonly isUpdateListing: boolean
  readonly isValidationComplete: boolean
  readonly listingPostModel: ListingPostModel[]
  readonly listingsData: ListingsModel[]
  readonly cleanLogStructure: any
  readonly userData?: UserData
  readonly currentTransactionData?: any
  readonly template?: any
  // readonly loginData: LoginModel;
}

export interface ListingsState {
  readonly listingsData: ListingsModel[]
}

export interface UpdateStatusReqModel {
  listing_id: number
  is_active: boolean
}

export interface FavoritesReqModel {
  listing_id: number
  user_id: number
}

export interface DeleteListingReqModel {
  listing_id: number
  user_id: number
  post_id: number
}

export interface CheckoutRequestModel {
  user_id: number
  success_url: string
  cancel_url: string
  item_id: number
  email?: string
  item_name: string
  price: number
}

export interface TransactionRequestModel {
  user_id: number
  listing_id: number
  session_id?: string
  is_free_use?: boolean

  id_verify?: boolean
}

export interface CleanRequestModel {
  listing_id: number
  user_id: number
  logged_at: Date
  items: string
}

export interface SelectedListingRequestModel {
  listing_id: number
  user_id: number
}

export interface CleanGetModel {
  listing_id: number
  limit: number
  page: number
}

export interface ListingUpdateModel {
  id: number
  key: keyof ListingModel
  value: any
}

export interface SearchListingReqModel {
  user_id: number
  service?: number
  location?: string
  hour?: number
  minutes?: number
}

export interface SearchListingsReqModel {
  service?: number
  location?: string
  hour?: number
  minutes?: number
}

export interface ListingPostModel {
  location: string
  title: string
  postId: number
}
