import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Select,
  Theme,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  Grid,
  Tooltip,
} from '@mui/material'
import UIConstants from '../../../../theme/constants/ui-constants'
import AddItem from '../components/AddItem'
import ListingData from '../components/ListingData'
import { ListingModel } from '../../../../store/app/listing/types'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { fetchTemplate } from '../../../../store/app/listing/actions'


const useStyle = makeStyles((theme: Theme) => ({
  header: {
    minHeight: '75px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 5rem',
  },
  body: {
    height: '100%',
    backgroundColor: UIConstants.foreground,
    padding: '4rem',
    overflowY: 'auto',
  },
  addButton: {
    backgroundColor: UIConstants.primaryBgColor,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: UIConstants.highlightColor,
    },
  },
  text: {
    color: UIConstants.primaryBgColor,
  },
  Subtext: {
    color: UIConstants.subText,
    fontSize: '12px',
  },
  PriceButtons: {
    backgroundColor: '#E6F7FF',
  },
  SubscribeText: {
    color: UIConstants.subText,
    fontSize: '9px',
    marginTop: '-10px',
    '&:hover': {
      color: UIConstants.highlightColor,
    },
  },
  formControl: {
    margin: `${theme.spacing(2)} ${theme.spacing(2)}`,
    display: 'flex',
  },
  Listing: {
    color: UIConstants.primaryBgColor,
    margin: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export default function Listing() {
  const classes = useStyle()
  const [open, setOpen] = React.useState(false)
  const [selectedLisitng, setSelectedLisitng] = React.useState<
    ListingModel | undefined
  >(undefined)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleDialogClose = () => {
    setOpen(false)
    setSelectedLisitng(undefined)
  }
  const dispatch = useDispatch()
  const { userData } = useSelector((state: ApplicationState) => state.user)
  const { listingData, template } = useSelector(
    (state: ApplicationState) => state.listing
  )
  const [rulesTemplate, setRulesTemplate] = useState<[]>([]);

  //dispatch the template
  useEffect(() => {
    if(userData.id) {
      dispatch(fetchTemplate(userData.id))
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if(template) {
      setRulesTemplate(template);
    }
  }, [template])

  //MUST REFACTOR
  return (
    <div className="h-full flex flex-col">
      <div className={classes.header}>
        <div className="flex items-center gap-5">
          <FormControl sx={{ width: '100px' }}>
            <InputLabel>Listings</InputLabel>
            <Select value={'all'} label="Listings" sx={{ height: '40px' }}>
              <MenuItem value={'all'}>All</MenuItem>
            </Select>
          </FormControl>
          <TextField
            placeholder="Search for a listing"
            type="text"
            variant="outlined"
            size="small"
          ></TextField>
        </div>
        <div>
          <Tooltip
            title={
              listingData.length === 4 ?
              'You have reached the maximum number of listings.' : ''
            }
            followCursor
          >
            <div>
              <Button
                className={classes.addButton}
                disabled={listingData.length === 4}
                variant="contained"
                disableElevation
                onClick={handleClickOpen}
              >
                Add Item
              </Button>
            </div>
          </Tooltip>
          {/* pop-up */}
          <AddItem
            open={open}
            handleClose={handleDialogClose}
            listingData={selectedLisitng}
            template={rulesTemplate}
          />
        </div>
      </div>
      <div className={classes.body}>
        {/* ADD LISTINGS HERE */}

        <ListingData />
      </div>
    </div>
  )
}
