import React, { useEffect, useRef, useState } from 'react'
import { useGetUserType } from '../../../composables'
import { makeStyles } from '@mui/styles'
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  IconButton,
  Modal,
  Popover,
  Stack,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import ListingReviews from './ListingReviews'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery'
import PhoneIcon from '@mui/icons-material/Phone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import TsekmoButton from '../../TsekmoButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'
import {
  checkoutListing,
  fetchSelectedListing,
  setFavoritesStatus,
  verifyIDRequest,
} from '../../../../store/app/listing/actions'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { ApplicationState } from '../../../../store'
import moment from 'moment'
import {
  CheckoutRequestModel,
  ListingModel,
} from '../../../../store/app/listing/types'
import { createTransaction } from '../../../../store/app/listing/actions'
import ImageGrid from '../../../../theme/components/image-list/ImageGrid'
import ImageCarouselList from '../../../../theme/components/image-list/ImageCarousel'
import CustomerListingForm from '../components/CustomerListingForm'
import { fetchListingUserData } from '../../../../store/app/listing/actions'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import UIConstants from '../../../../theme/constants/ui-constants'
import Verification from '../components/Verification'
import Webcam from 'react-webcam'

import HomeIcon from '@mui/icons-material/Home'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PersonIcon from '@mui/icons-material/Person'
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra'
import UseBathroomForm from '../components/UseBathroomForm'
import { InfoOutlined } from '@mui/icons-material'
import ShareIcon from '@mui/icons-material/Share'
const useStyle = makeStyles((theme: Theme) => ({
  listCol: {
    columnCount: 2,
    listStyleType: 'circle',
    listStylePosition: 'inside',
    marginTop: '0.5rem',
  },
}))

interface Image {
  location: string
  name: string
  size: number
  key: string
}

interface CustomFileWithType extends File {
  fileName?: string
  imageCategory?: string
}

interface ListingHomeProps {
  is_public?: boolean
}

function ListingHome({ is_public = false }: ListingHomeProps) {
  const routerData = useLocation().state
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyle()
  const [isBusinessOwner, setIsBusinessOwner] = useState<boolean>(false)
  const [submittingPayment, setSubmittingPayment] = useState<boolean>(false)
  const [openUseBathroom, setOpenUseBathroom] = useState<boolean>(false)
  const [openVerification, setOpenVerification] = useState<boolean>(false)
  const [hasUserDiscount, setHasUserDiscount] = useState<boolean>(false)
  const [hasIdVerification, setHasIdVerification] = useState<boolean>(false)
  const [anchorEl1, setAnchorEl1] = useState<any>(null)
  const [idImageList, setIdImageList] = useState<any>({
    front: null,
    back: null,
    selfie: null,
  })
  const defaultUserDiscountList = {
    homeless: false,
    parent: false,
    paying: false,
    mobility: false,
    senior: false,
    tourist: false,
  }
  const [userDiscountList, setUserDiscountList] = useState<any>(
    defaultUserDiscountList
  )
  const [openWebcam, setOpenWebcam] = useState<boolean>(false)
  const [pictureMode, setPictureMode] = useState<any>('')
  const [localIsFavorite, setIsFavorite] = useState<boolean>(false)
  const [isHoveringFavoriteButton, setIsHoveringFavoriteButton] =
    useState<boolean>(false)
  const [images, setImages] = useState<Image[]>([])
  const currentUserType = useGetUserType()
  const { selectedListing } = useSelector(
    (state: ApplicationState) => state.listing
  )
  const webcamRef = useRef<any>(null)
  const { userData } = useSelector((state: ApplicationState) => state.app)
  const ownerData = useSelector(
    (state: ApplicationState) => state.listing.userData
  )
  const { loading } = useSelector((state: ApplicationState) => state.listing)

  const listing_id = Number(
    window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
  )

  const handleSubmit = () => {
    const formData = new FormData()
    formData.append('user_id', userData.id as any)
    formData.append(
      'listing_id',
      selectedListing && (selectedListing.id as any)
    )
    formData.append('notif_token', '')
    formData.append('uploadedImages', idImageList as any)

    dispatch(verifyIDRequest(formData))
  }
  useEffect(() => {
    if (currentUserType === 'business-owner' || currentUserType === 'admin') {
      setIsBusinessOwner(true)
    }
  }, [currentUserType])

  useEffect(() => {
    if (userData.id) {
      dispatch(
        fetchSelectedListing({
          listing_id: listing_id,
          user_id: userData.id,
        })
      )
    }
  }, [userData])

  const onClickPayFree = () => {
    if (userData.id && selectedListing) {
      dispatch(
        createTransaction({
          user_id: userData.id,
          listing_id: selectedListing.id,
          is_free_use: selectedListing?.free_use,
        })
      )
      setOpenUseBathroom(true)
    }
  }

  // useEffect(() => {
  //   if (submittingPayment && !loading.paymentData) {
  //     // setOpenReviewForm(true)
  //     setSubmittingPayment(false)
  //   }
  // }, [loading.paymentData])

  const handleCloseUseBathroom = (_e: any, reason: any) => {
    if (reason && reason === 'backdropClick') return
    setOpenUseBathroom(false)
  }

  const onClickPay = () => {
    if (userData.id && selectedListing) {
      const checkoutRequestModel: CheckoutRequestModel = {
        cancel_url: `${process.env.REACT_APP_DOMAIN}${'/customer'}`,
        success_url: `${process.env.REACT_APP_DOMAIN}${'/customer'}`,
        user_id: userData.id,
        item_id: selectedListing.id,
        email: userData.email,
        item_name: selectedListing.title,
        price: selectedListing.price,
      }
      dispatch(checkoutListing(checkoutRequestModel))
      // setOpenReviewForm(true)
    }
  }

  const handleOpenWebcam = (mode: string) => {
    setPictureMode(mode)
    setOpenWebcam(true)
  }

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot()

    const imageBlob = await fetch(imageSrc).then((res) => res.blob())
    const imageFile: CustomFileWithType = new File(
      [imageBlob],
      `${pictureMode}.jpg`,
      {
        type: 'image/jpeg',
      }
    )
    imageFile.fileName = Date.now() + '-' + imageFile.name
    imageFile.imageCategory = pictureMode

    switch (pictureMode) {
      case 'front':
        setIdImageList({ ...idImageList, front: [imageFile] })
        // setPictureMode('back')
        break
      case 'back':
        setIdImageList({ ...idImageList, back: [imageFile] })
        // setPictureMode('selfie')
        break
      case 'selfie':
        setIdImageList({ ...idImageList, selfie: [imageFile] })
        // setOpenVerification(false)
        break
    }
    setOpenWebcam(false)
  }, [webcamRef, pictureMode])

  const handleAddIdImage = (file: any, type: string) => {
    const imageFile: CustomFileWithType = file
    imageFile.fileName = Date.now() + '-' + imageFile.name
    imageFile.imageCategory = type
    setIdImageList((prevState: any) => ({ ...prevState, [type]: [imageFile] }))
  }

  const deleteIdImage = (type: string) => {
    setIdImageList((prevState: any) => ({ ...prevState, [type]: null }))
  }

  // useEffect(() => {
  //   console.log(idImageList)
  // }, [idImageList])

  useEffect(() => {
    if (selectedListing) {
      dispatch(fetchListingUserData(selectedListing.user_id))
      if (selectedListing.uploads !== '') {
        setImages(JSON.parse(selectedListing.uploads))
      }
    }
  }, [selectedListing])

  const updateFavoriteStatus = () => {
    if (userData.id && selectedListing?.id) {
      dispatch(
        setFavoritesStatus({
          user_id: userData.id,
          listing_id: selectedListing?.id,
        })
      )
    }
  }

  const handleUseProcess = () => {
    if (is_public) {
      navigate('/signup')
    }
    if (selectedListing?.valid_id_required) {
      setOpenVerification(true)
    } else {
      processPayment()
    }
  }

  // const handleUseProcess = () => {
  //   if (is_public) {
  //     navigate(`/signup`)
  //   }
  //   if (selectedListing?.valid_id_required) {
  //     setHasIdVerification(true)
  //   } else {
  //     processPayment()
  //   }
  // }

  // useEffect(() => {
  //   if (hasIdVerification) {
  //     console.log(hasIdVerification)
  //     setOpenVerification(true)
  //   } else {
  //     processPayment()
  //   }
  // }, [hasIdVerification])

  const processPayment = () => {
    if (selectedListing?.price === 0) {
      return onClickPayFree()
    } else {
      return onClickPay()
    }
  }

  const handleClickPopover = (e: any) => {
    setAnchorEl1(e.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl1(null)
  }

  const VerificationCam = () => (
    <div className="w-fit h-fit flex-col gap-2 justify-center">
      <div>
        <Webcam
          ref={webcamRef}
          audio={false}
          screenshotFormat="image/jpeg"
          disablePictureInPicture
        />
      </div>
      <div className="p-4 bg-white flex flex-col items-center relative">
        <IconButton
          className="absolute top-0 right-0"
          onClick={() => setOpenWebcam(false)}
        >
          <CloseOutlinedIcon />
        </IconButton>
        <TsekmoButton onClick={capture}>
          <CameraAltOutlinedIcon />
        </TsekmoButton>
      </div>
    </div>
  )

  const handleSelectDiscount = (type: string) => {
    setUserDiscountList((prevState: any) => ({
      ...prevState,
      [type]: !prevState[type],
    }))
  }

  // useEffect(() => {
  //   console.log(userDiscountList)
  // }, [userDiscountList])

  return (
    <div
      className={`w-full text-mainText bg-foreground ${
        is_public && 'p-8 px-14'
      }`}
    >
      <div className="grid grid-cols-1 lg:grid-cols-8 w-full p-8 gap-y-4 lg:gap-4">
        <div className="col-span-5 px-8 py-4 rounded-lg shadow-sm bg-white">
          <div className="flex flex-col">
            <h1 className="text-xl font-semibold text-primaryBg">
              {selectedListing?.title}
            </h1>
            <div className="w-full flex justify-between">
              <span>4.7 Reviews</span>
            </div>
            <div className="w-full flex justify-between">
              <button
                className="border-0 outline-none flex gap-1 hover:underline items-center"
                onMouseEnter={() => setIsHoveringFavoriteButton(true)}
                onMouseLeave={() => setIsHoveringFavoriteButton(false)}
                onClick={updateFavoriteStatus}
              >
                <span>Add to favorites</span>
                {isHoveringFavoriteButton || selectedListing?.is_favorite ? (
                  <FavoriteIcon
                    sx={{ color: 'red', width: '20px', height: '20px' }}
                  />
                ) : (
                  <FavoriteBorderIcon
                    sx={{ color: 'red', width: '20px', height: '20px' }}
                  />
                )}
              </button>

              <Button
                sx={{
                  display: 'flex',
                  color: UIConstants.subText,
                  gap: '5px',
                  textTransform: 'capitalize',
                  fontSize: '14px',
                }}
                onMouseEnter={(e) => handleClickPopover(e)}
              >
                <p>Share</p>
                <ShareIcon sx={{ width: '15px', height: '15px' }} />
              </Button>
              <Popover
                open={Boolean(anchorEl1)}
                anchorEl={anchorEl1}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div className="p-4" onMouseLeave={handleClosePopover}>
                  <div className="flex justify-center gap-2">
                    <FacebookShareButton
                      url={''}
                      quote={'Tsekmo Community'}
                      hashtag={'#Tsekers'}
                    >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={''}
                      title={'Tsekmo Community'}
                      hashtags={['#Tsekers']}
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <TelegramShareButton url={''} title={'Tsekmo Community'}>
                      <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                    <LinkedinShareButton url={''} title={'Tsekmo Community'}>
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <RedditShareButton url={''} title={'Tsekmo Community'}>
                      <RedditIcon size={32} round={true} />
                    </RedditShareButton>
                    <WhatsappShareButton url={''} title={'Tsekmo Community'}>
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                    <EmailShareButton url={''} title={'Tsekmo Community'}>
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                  </div>
                  <div className="flex gap-2 items-center mt-3">
                    <TextField size="small"></TextField>
                    <Button
                      sx={{
                        borderRadius: '100%',
                        padding: '8px',
                        minWidth: 'fit-content',
                        color: UIConstants.subText,
                      }}
                    >
                      <ContentPasteIcon />
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
            <div className="mt-8">
              <ImageCarouselList height="30rem" imageFileList={images} />
            </div>
            <h1 className="mt-8 font-semibold text-md text-primaryBg">
              Vanities
            </h1>
            <div className="w-full">
              {selectedListing?.amenities &&
                JSON.parse(selectedListing?.amenities as string).map(
                  (item: any) => (
                    <Chip key={item.id} label={item.text} size="small" />
                  )
                )}
            </div>
            <div className="w-full mt-4 h-[1px] opacity-50 bg-subText"></div>
            <h1 className="mt-4 font-semibold text-md text-primaryBg">Rules</h1>
            <div className="w-full">
              <ul className={classes.listCol}>
                <li>{selectedListing?.rule}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-span-3 flex flex-col gap-4">
          <div className="px-8 py-4 rounded-lg shadow-sm bg-white flex flex-col">
            <div className="flex gap-2 items-center">
              <img
                className="w-[40px] h-[40px] rounded-full"
                src={ownerData?.user_info?.image_url}
              />
              <h1 className="font-semibold text-lg text-primaryBg">
                {ownerData &&
                  `${ownerData.user_info?.first_name} ${ownerData.user_info?.last_name}`}
              </h1>
            </div>
            <span className="text-sm font-semibold mt-2">
              {ownerData && ownerData.email ? `Email: ` : ''}
              <span className="font-normal">
                {ownerData && ownerData.email ? ownerData.email : ''}
              </span>
            </span>
            <span className="text-sm font-semibold">
              {ownerData && ownerData.mobile_number ? `Contact: ` : ''}
              <span className="font-normal">
                {ownerData && ownerData.mobile_number
                  ? ownerData.mobile_number
                  : ''}
              </span>
            </span>
          </div>

          <div className="px-8 py-4 rounded-lg shadow-sm bg-white">
            <div className="flex flex-col">
              <h1 className="text-md font-semibold text-primaryBg">
                Instructions
              </h1>
              <span className="text-sm text-mainText">
                {selectedListing?.instructions}
              </span>
            </div>
          </div>

          <div className="px-8 py-4 rounded-lg shadow-sm bg-white flex flex-col gap-4 text-primaryBg">
            <div className="grid grid-cols-3">
              <div className="col-span-1 flex flex-col gap-2">
                <div>
                  <LocalOfferIcon />
                  <span className="ml-2">
                    {selectedListing?.price === 0
                      ? 'Free'
                      : `$${selectedListing?.price}`}
                  </span>
                </div>
              </div>
              <div className=" col-span-2">
                <div>
                  <BrowseGalleryIcon />
                  <span className="ml-2">
                    {moment(selectedListing?.open_hour).format('h:mm A') ===
                    moment(selectedListing?.close_hour).format('h:mm A')
                      ? '24 Hours'
                      : `${moment(selectedListing?.open_hour).format(
                          'h:mm A'
                        )} to
                    ${moment(selectedListing?.close_hour).format('h:mm A')}`}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <LocationOnIcon />
              <span className="ml-2">
                {selectedListing?.location &&
                  JSON.parse(selectedListing?.location)?.description}
              </span>
            </div>
            <div>
              <PhoneIcon />
              <span className="ml-2">{selectedListing?.mobile_number}</span>
            </div>
            <div className="flex gap-2">
              <span>
                Discounts{' '}
                <Tooltip
                  title="Select a discount to use that's applicable to you. (Subject to Business Owner's Approval)"
                  placement="top"
                  arrow
                >
                  <InfoOutlined sx={{ width: '20px', marginTop: '-1px' }} />
                </Tooltip>{' '}
              </span>
              <div className="flex items-center">
                <Tooltip arrow placement="top" title="Homeless">
                  <Checkbox
                    onChange={() => handleSelectDiscount('homeless')}
                    sx={{ padding: '0 5px' }}
                    icon={<HomeIcon />}
                    checkedIcon={<HomeIcon />}
                    checked={userDiscountList.homeless}
                    color="primary"
                  />
                </Tooltip>
                <Tooltip arrow placement="top" title="Parent with Child">
                  <Checkbox
                    onChange={() => handleSelectDiscount('parent')}
                    sx={{ padding: '0 5px' }}
                    icon={<ChildFriendlyIcon />}
                    checkedIcon={<ChildFriendlyIcon />}
                    checked={userDiscountList.parent}
                    color="primary"
                  />
                </Tooltip>
                <Tooltip arrow placement="top" title="Paying Customer">
                  <Checkbox
                    onChange={() => handleSelectDiscount('paying')}
                    sx={{ padding: '0 5px' }}
                    icon={<MonetizationOnIcon />}
                    checkedIcon={<MonetizationOnIcon />}
                    checked={userDiscountList.paying}
                    color="primary"
                  />
                </Tooltip>

                <Tooltip arrow placement="top" title="Mobility Disability">
                  <Checkbox
                    onChange={() => handleSelectDiscount('mobility')}
                    sx={{ padding: '0 5px' }}
                    icon={<AccessibilityIcon />}
                    checkedIcon={<AccessibilityIcon />}
                    checked={userDiscountList.mobility}
                    color="primary"
                  />
                </Tooltip>
                <Tooltip arrow placement="top" title="Senior Citizen">
                  <Checkbox
                    onChange={() => handleSelectDiscount('senior')}
                    sx={{ padding: '0 5px' }}
                    icon={<PersonIcon />}
                    checkedIcon={<PersonIcon />}
                    checked={userDiscountList.senior}
                    color="primary"
                  />
                </Tooltip>
                <Tooltip arrow placement="top" title="Tourist">
                  <Checkbox
                    onChange={() => handleSelectDiscount('tourist')}
                    sx={{ padding: '0 5px' }}
                    icon={<AirlineSeatReclineExtraIcon />}
                    checkedIcon={<AirlineSeatReclineExtraIcon />}
                    checked={userDiscountList.tourist}
                    color="primary"
                  />
                </Tooltip>
              </div>
            </div>
            {isBusinessOwner ? (
              ''
            ) : (
              <TsekmoButton
                onClick={() => {
                  handleUseProcess()
                }}
              >
                {!is_public ? (
                  !submittingPayment ? (
                    selectedListing?.price === 0 ? (
                      'Free'
                    ) : (
                      `$${selectedListing?.price}`
                    )
                  ) : (
                    <CircularProgress size={25} />
                  )
                ) : (
                  'Use'
                )}
              </TsekmoButton>
            )}
          </div>
        </div>
        <div className="col-span-6 px-8 py-4 rounded-lg shadow-sm bg-white">
          <ListingReviews listing_id={listing_id} />
        </div>
        <div className="col-span-2">
          <div className="px-8 py-4 rounded-lg shadow-sm bg-white">
            <h1 className="font-semibold text-lg text-primaryBg">
              Other listings by host
            </h1>
            <div className="flex flex-col gap-4 mt-2">
              {[1, 2, 3].map((item) => (
                <div className="max-w-[100%] border-b-[1px] py-4" key={item}>
                  <img
                    className="aspect-square object-fit object-center"
                    alt="host-bathroom"
                    src="https://www.thespruce.com/thmb/ji87XabKvQ5KVoidSrj0_Gzu1oQ=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/fancy-bathroom-ideas-4325947-hero-4777bf14fe2b447b86a21e8a64194c29.jpg"
                  />
                  <h1 className="text-md font-semibold mt-2 text-primaryBg">
                    Best Bathroom
                  </h1>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openUseBathroom}
        onClose={handleCloseUseBathroom}
        maxWidth={false}
      >
        {/* <CustomerListingForm
          handleCloseReviewForm={handleCloseReviewForm}
          listing={selectedListing}
        /> */}
        <UseBathroomForm
          handleCloseUseBathroom={handleCloseUseBathroom}
          listing={selectedListing}
        />
      </Dialog>
      <Dialog
        open={openVerification}
        onClose={() => setOpenVerification(false)}
        maxWidth="xl"
      >
        {openWebcam ? (
          <VerificationCam />
        ) : (
          <>
            <Verification
              idImageList={idImageList}
              handleOpenWebcam={handleOpenWebcam}
              userDiscountList={userDiscountList}
              handleSubmit={handleSubmit}
              deleteIdImage={deleteIdImage}
              handleAddPhoto={handleAddIdImage}
            />
          </>
        )}
      </Dialog>
    </div>
  )
}

export default ListingHome
