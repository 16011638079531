export default class UIConstants {
  static readonly rootBgColor = 'rgb(255, 255, 255)'
  static readonly appBarHeight = 80
  static readonly primaryFooterHeight = '40px'
  static readonly secondaryFooterHeight = '250px'
  static readonly primaryBgColor = '#1C2577'
  static readonly primaryColor = '#F2FDFF'
  static readonly secondaryColor = '#C5FFFF'
  static readonly infoColor = '#0075F2'
  static readonly highlightColor= '#2834a6'
  static readonly appBorderRadius = '8px'
  static readonly appFont = 'Inter'
  static readonly errorColor = '#d32f2f'
  static readonly drawerWidth = '90%'
  static readonly cardBorder = '1px solid #BDF5FF'
  static readonly cardBoxshadow = '0 1px 10px rgb(0 0 0 / 15%)'
  static readonly specialfontColor = '#7F675B'
  static readonly tableBorder = '1px solid  #c5ffff'
  static readonly mainText = '#1E293B'
  static readonly subText = '#64748B'
  static readonly accent = '#FFA500'
  static readonly foreground = '#F1F5F9'
  static readonly suggestion = '#cc4b4b'
  static readonly senderBubbleColor = '#1C2577';  
  static readonly recipientBubbleColor = '#C5FFFF';
}
