import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';

interface SocketContextType {
  socket: ReturnType<typeof io> | null;
  isConnected: boolean;
  connect: () => Promise<void>;
  disconnect: () => void;
  emit: (event: string, data: any) => void;
}

const SocketContext = createContext<SocketContextType>({
  socket: null,
  isConnected: false,
  connect: async () => {},
  disconnect: () => {},
  emit: () => {},
});

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [socket, setSocket] = useState<ReturnType<typeof io> | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const { userData } = useSelector((state: ApplicationState) => state.user);

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:2017';
    const newSocket = io(socketUrl, {
      transports: ['websocket', 'polling'],
    });

    newSocket.on('connect', () => {
      console.log(`Connected with ID: ${newSocket.id}`);
      setIsConnected(true);

      if (userData?.id) {
        newSocket.emit('registerCustomer', { customerUserId: userData.id });
      }

      // newSocket.off('AccountUpdated').on('AccountUpdated', (data) => {
      //   console.log('Received AccountUpdated event:', data);
      // });

      // newSocket.off('newChatMessage').on('newChatMessage', (data) => {
      //   console.log('Received Chat Message Event:', data);
      // });
    });

    setSocket(newSocket);

    return () => {
      // newSocket.off('AccountUpdated');
      // newSocket.off('newChatMessage');
      newSocket.disconnect();
      setIsConnected(false);
    };
  }, [userData?.id]);

  const connect = useCallback(async () => {
    if (!socket) {
      throw new Error('Socket is not initialized');
    }
    await socket.connect();
    setIsConnected(true);
  }, [socket]);

  const disconnect = useCallback(() => {
    if (!socket) {
      throw new Error('Socket is not initialized');
    }
    socket.disconnect();
    setIsConnected(false);
  }, [socket]);

  return (
    <SocketContext.Provider value={{
      socket,
      isConnected,
      connect,
      disconnect,
      emit: (event, data) => socket?.emit(event, data),
    }}>
      {children}
    </SocketContext.Provider>
  );
};