import {
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { InputText } from '../../../../theme/components/input/TextField'
import UIConstants from '../../../../theme/constants/ui-constants'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { CustomButton } from '../../../../theme/components/buttons/Button'
import { createOrUpdateCleanlogStructure } from '../../../../store/app/listing/actions'
import {
  ListingModel,
  ListingPostModel,
} from '../../../../store/app/listing/types'
import { IncDecCounter } from '../../../../theme/components/input/IncDecCounter'
import { Grid } from '@mui/material'
import { Toggle } from '../../../../theme/components/input/Toggle'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import MobileTimePicker from '@mui/lab/MobileTimePicker'
import { ClockIcon } from '../../../../theme/components/icons/ClockIcon'
import LocationSelector from '../../../../theme/components/input/LocationSelector'
import { PhoneInput } from '../../../../theme/components/input/PhoneField'
import { IncrementIcon } from '../../../../theme/components/icons/IncrementIcon'
import UploadImageList from '../../../../theme/components/image-list/UploadImageList'
import { useEffect, useMemo, useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import {
  createListing,
  fetchListingTypes,
  updateListing,
  fetchListingPost,
} from '../../../../store/app/listing/actions'
import { isJson } from '../../../../app/utils/common-utils'
import CleanLog from './CleanLog/CleanLog'
import { useCleanLog } from './CleanLog/HandleFunctions'
import {
  BathroomData,
  CleaningData,
  RefillableData,
  ShowerData,
  ToiletData,
  vanitiesData,
} from './CleanLog/Data'
import { useNavigate } from 'react-router-dom'
import DropUpload from '../../../common/DropUpload'
import { LogItem, IFormInput, LimitationsEnum } from './types'
import { getSpecificProduct } from '../../../../store/app/user/actions'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      backgroundImage: 'none',
    },
  },
  heading: {
    margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
    textAlign: 'center',
    '& .mainHeading': {
      fontWeight: 600,
      fontSize: '18px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    '& .subHeading': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '27px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
      textAlign: 'left',
    },
  },
  addListingInputBox: {
    minWidth: '250px',
    margin: `${theme.spacing(4)} 0`,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: '350px',
    },
  },
  addListingBox: {
    background: UIConstants.rootBgColor,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `0 ${theme.spacing(3)}`,
    position: 'relative',
    top: '4rem',
    height: 'calc(100vh - 9rem)',
    [theme.breakpoints.up('sm')]: {
      position: 'unset',
      maxWidth: '35rem',
      margin: 'auto',
      top: 'unset',
      padding: `${theme.spacing(3)}`,
    },
  },
  becomeAHost: {
    color: UIConstants.primaryBgColor,
    margin: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  openHourText: {
    color: UIConstants.subText,
    cursor: 'pointer',
    '&:hover': {
      color: UIConstants.highlightColor,
    },
  },
  hours: {
    color: UIConstants.highlightColor,
  },
  noteText: {
    fontSize: '10px',
    marginTop: '0px !important',
  },
  SubscribeText: {
    color: UIConstants.subText,
    fontSize: '9px',
    marginTop: '-10px',
    '&:hover': {
      color: UIConstants.highlightColor,
    },
  },
}))

interface AddListingProps {
  open: boolean
  handleClose: () => void
  listingData?: ListingModel
  template?: any
}

const initialLogItems = {
  bathroom: BathroomData,
  toilet: ToiletData,
  vanities: vanitiesData,
  shower: ShowerData,
  cleaning: CleaningData,
  refillables: RefillableData,
};

export default function AddListing({
  open,
  handleClose,
  listingData,
  template
}: AddListingProps) {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [logItems, setLogItems] = useState<{[key: string]: LogItem[]}>(initialLogItems);
  const [currentFileList, setCurrentFileList] = useState<any>([])
  const [submittingListing, setSubmittingListing] = useState<boolean>(false)
  const [remoteFileList, setRemoteFileList] = useState<any>([])
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const countries = process.env.REACT_APP_SUPPORTED_COUNTRIES?.split(',')
  const { loading } = useSelector((state: ApplicationState) => state.listing)
  const dispatch = useDispatch()
  const { listingPostModel } = useSelector(
    (state: ApplicationState) => state.listing
  )
  const { businessInfo } = useSelector((state: ApplicationState) => state.user)
  const listingTypes: Record<string, number> = {
    Male: 1,
    Female: 2,
    Unisex: 3,
    Allgender: 4,
    Family: 5,
  }

  const [selectedLocationData, setSelectedLocationData] = useState<string>('')
  const [timeDifference, setTimeDifference] = useState<string | number>('')
  const { userData, limitations } = useSelector((state: ApplicationState) => state.user)
  const [isAnti, setIsAnti] = useState<boolean>(false)
  const [isBasic, setIsBasic] = useState<boolean>(false)
  const currentDate = new Date()

  const {
    control,
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    reset,
    formState: { isValid, errors, isDirty },
  } = useForm<IFormInput>({
    mode: 'all',
  })
  const [postList, setPostList] = useState<ListingPostModel[]>([]);

  const limitationsArray = limitations?.services?.limitations || [];
  // this determine the limitation
  const hasLimitation = (feature: any) => limitationsArray.includes(feature);

  const formatDescription = (description?: string): string => {
    if (!description) return "";
  
    try {
      const parsedDescription = JSON.parse(description);
  
      return parsedDescription
        .map((section: any) => {
          const sectionTitle = `${section.title}`;
          const content = section.content
            .map((item: any) => {
              const subTitle = `  - ${item.sub_title}`;
              const subContent = `    ${item.sub_content}`;
              return `${subTitle}\n${subContent}`;
            })
            .join("\n");
  
          return `${sectionTitle}\n${content}`;
        })
        .join("\n\n");
    } catch (error) {
      console.error("Error parsing template description:", error);
      return "";
    }
  };

  const formattedDescription = formatDescription(template?.description);

  useEffect(() => {
    let oldModel = [...listingPostModel];
    if (oldModel.length > 0) {
      oldModel.push({
        postId: -999,
        title: 'my bathroom listing',
        location: '',
      });
    } else {
      oldModel = [{ postId: -999, title: 'my bathroom listing', location: '' }];
    }
    setPostList(oldModel);
  }, [listingPostModel]);

  useEffect(() => {
    //we set current file list whenever we update currentFileList state
    setValue('file_list', currentFileList, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [currentFileList, setValue])

  useEffect(() => {
    if (userData.id) {
      dispatch(fetchListingPost(userData.id))
      dispatch(getSpecificProduct(userData.id))
      setIsAnti(isAntiVandal(userData))
      setIsBasic(isBasicSub(userData))
    }
  }, [dispatch, userData])

  useEffect(() => {
    if (userData.id) {
      setIsAnti(isAntiVandal(userData))
      setIsBasic(isBasicSub(userData))
    }
  }, [userData])

  useEffect(() => {
    if(limitations) {
      console.log(`Check limitations : ${JSON.stringify(limitations, null, 2)}`)
    }
  }, [limitations])

  const isBasicSub = (u: any) => {
    var packages = u.package_subscribed
    if (packages) {
      const parsedPackages: any[] = JSON.parse(packages)
      const myPackage = 'basic'

      if (parsedPackages.includes(myPackage)) {
        return true
      }
    }

    return false
  }

  const isAntiVandal = (u: any) => {
    var packages = u.package_subscribed
    if (packages) {
      const parsedPackages: any[] = JSON.parse(packages)
      const myPackage = 'anti-vandal'

      if (parsedPackages.includes(myPackage)) {
        return true
      }
    }

    return false
  }

  const addPhoto = (event: any) => {
    if (currentFileList === null) {
      setCurrentFileList(event.target.files)
    } else {
      setCurrentFileList([
        ...currentFileList,
        ...Array.from(event.target.files),
      ])
    }
  }

  const handleDeleteImage = (index: any) => {
    let fileList = currentFileList
    if (remoteFileList.length > 0) {
      fileList = remoteFileList
    }

    fileList.splice(index, 1)
    setCurrentFileList([...fileList])
  }

  useEffect(() => {
    dispatch(fetchListingTypes())
    if (userData.id && !listingData) {
      dispatch(fetchListingPost(userData.id))
    }
    if (listingData) {
      if (userData.id) {
        dispatch(fetchListingPost(userData.id, listingData.post_id))
      }
      reset({
        listing_post: listingData.post_id,
        listing_type: listingData.list_type_id,
        cleanlog_structure: listingData.cleanlog_structure,
        instructions: listingData.instructions,
        rule: listingData.rule,
        price: listingData.price,
        preferred_price: listingData.preferred_price || '',
        free_use: listingData.free_use,
        open_hour: new Date(listingData.open_hour),
        close_hour: new Date(listingData.close_hour),
        is_active: listingData.is_active,
        mobile_number: listingData.mobile_number,
        file_list: null,
        location:
          listingData?.location && isJson(listingData?.location)
            ? JSON.parse(listingData?.location).label
            : '',
        title: listingData.title,
        is_valid_id_required: listingData.valid_id_required,
      })
      setRemoteFileList(JSON.parse(listingData?.uploads))
      setSelectedLocationData(listingData?.location || '')
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [listingData])

  const onSubmit = (data: any) => {
    if (userData.id) {
      const form_data = new FormData()
      data.open_hour = new Date(data.open_hour.setSeconds(0)).toString()
      data.close_hour = new Date(data.close_hour.setSeconds(0)).toString()

      let amenities
      const selectedItems = [
        { name: 'Refillables', items: logItems.refillables },
        { name: 'Vanities', items: logItems.vanities },
        { name: 'Bathroom', items: logItems.bathroom },
        { name: 'Toilet', items: logItems.toilet },
        { name: 'Shower', items: logItems.shower },
        { name: 'Cleaning', items: logItems.cleaning },
      ]

      if (!data.cleanlog_structure) {
        setValue('cleanlog_structure', JSON.stringify(selectedItems) as string)
        form_data.set('cleanlog_structure', JSON.stringify(selectedItems))
        amenities = selectedItems?.find(
          (item: any) => item?.name === 'Vanities'
        )?.items
        dispatch(
          createOrUpdateCleanlogStructure({
            user_id: userData.id,
            cleanlog_structure: JSON.stringify(selectedItems),
          })
        )
      } else {
        amenities = JSON.parse(data.cleanlog_structure).find(
          (item: any) => item.name === 'Vanities'
        ).items
        dispatch(
          createOrUpdateCleanlogStructure({
            user_id: userData.id,
            cleanlog_structure: data.cleanlog_structure,
          })
        )
      }

      form_data.append('amenities', JSON.stringify(amenities))

      for (let key in data) {
        if (key === 'file_list') {
          const imgList = data[key]
          for (let i = 0; i < imgList?.length; i++) {
            form_data.append('uploadedImages', imgList[i])
          }
        } else if (key === 'location') {
          form_data.append('location', businessInfo?.location as string)
        } else {
          if (!form_data.has(key)) {
            form_data.append(key, data[key])
          }
        }
      }

      if (data.price > 0) {
        form_data.append('free_use', 'false')
        form_data.append('charge_customer', 'true')
      } else {
        form_data.append('free_use', 'true')
        form_data.append('charge_customer', 'false')
      }

      form_data.append('user_id', userData.id.toString())
      console.log(userData)
      const qr_code = `${process.env.REACT_APP_DOMAIN}/customer/listing/`
      form_data.append('qr_code', qr_code)
      form_data.append('post_id', data.listing_post)
      form_data.append('valid_id_required', data.is_valid_id_required)

      if (isEditMode && listingData) {
        form_data.append('listing_id', `${listingData.id}`)
        dispatch(updateListing(form_data))
        setSubmittingListing(true)
      } else {
        dispatch(
          createListing({
            user_id: userData.id.toString(),
            formData: form_data,
          })
        )
        setSubmittingListing(true)
      }
    }
  }

  useEffect(() => {
    if (submittingListing && !loading.creatingListing) {
      onDialogClose()
      setSubmittingListing(false)
    }
  }, [loading.creatingListing, submittingListing])

  const checkChanage = (post: any) => {
    setValue('title', post.title, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue('listing_post', post.postId, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue(
      'location',
      post?.location && isJson(post?.location)
        ? JSON.parse(post?.location).label
        : '',
      {
        shouldDirty: true,
      }
    )
    setSelectedLocationData(post?.location || '')
  }

  const onDialogClose = () => {
    if (userData.id) {
      dispatch(fetchListingPost(userData.id))
    }
    reset({
      listing_type: 0,
      cleanlog_structure: '',
      // instructions: getRules.description,
      // rule: getRules.rules,
      instructions: template ? template.description : 'no description',
      rule: template ? template.rules : 'no rules',
      price: 0,
      preferred_price: '',
      free_use: false,
      open_hour: new Date(),
      close_hour: new Date(),
      is_active: true,
      is_valid_id_required: false,
      mobile_number: '',
      file_list: null,
      location: '',
      title: '',
    })
    handleClose()
  }

  const handleHours = () => {
    setValue(
      'open_hour',
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0
      )
    )
    setValue(
      'close_hour',
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0
      )
    )
  }

  useEffect(() => {
    const newTimeDifference = calculateTimeDifference()
    setTimeDifference(newTimeDifference)
  }, [watch('open_hour'), watch('close_hour')])

  const calculateTimeDifference = () => {
    const openHour: Date = getValues('open_hour')
    const closeHour: Date | null = getValues('close_hour')

    if (closeHour) {
      const isMidnightToMidnight =
        openHour.getHours() === 0 &&
        openHour.getMinutes() === 0 &&
        closeHour.getHours() === 0 &&
        closeHour.getMinutes() === 0

      let timeDifference

      if (isMidnightToMidnight) {
        timeDifference = '24:00'
      } else {
        const diffInMillis = Math.abs(closeHour.getTime() - openHour.getTime())
        const hours = Math.floor(diffInMillis / 36e5)
        const minutes = Math.floor((diffInMillis % 36e5) / 60000)
        timeDifference = `${hours}:${minutes.toString().padStart(2, '0')}`
      }

      return timeDifference
    }

    return ''
  }

 
  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onDialogClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={classes.root}>
        <Paper elevation={0} className={classes.addListingBox}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.heading}>
              <Typography className="mainHeading">
                {isEditMode
                  ? t('addListing.mainHeadingUpdate')
                  : t('addListing.mainHeading')}
              </Typography>
            </div>
            {businessInfo && (
              <div className={classes.addListingInputBox}>
                <Stack spacing={2}>
                  <Controller
                    name="listing_post"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        fullWidth
                        select
                        {...field}
                        label="Listing Type"
                        size="small"
                        error={!!errors?.listing_post}
                        helperText={errors?.listing_post?.message}
                      >
                        {postList?.map((listingPost: any, index: number) => (
                          <MenuItem
                            value={listingPost.postId}
                            key={listingPost.title + index}
                            onClick={() => checkChanage(listingPost)}
                          >
                            {listingPost.title}
                          </MenuItem>
                        ))}
                      </InputText>
                    )}
                  />

                  <Controller
                    name="listing_type"
                    control={control}
                    defaultValue={
                      Object.values(listingTypes).length > 0
                        ? Object.values(listingTypes)[0]
                        : 1
                    }
                    render={({ field }) => (
                      <InputText
                        fullWidth
                        select
                        {...field}
                        label="Bathroom Type"
                        size="small"
                        error={!!errors?.listing_type}
                        helperText={errors?.listing_type?.message}
                      >
                        {Object.entries(listingTypes).map(
                          ([key, value]: [string, number], index: number) => (
                            <MenuItem value={value} key={key + index}>
                              {key}
                            </MenuItem>
                          )
                        )}
                      </InputText>
                    )}
                  />

                  <Controller
                    name="title"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <InputText
                        fullWidth
                        variant="outlined"
                        error={!!errors?.title}
                        size="small"
                        label={t('addListing.addTitle')}
                        {...register('title', {
                          required: t('addListing.required') as string,
                        })}
                        {...field}
                        helperText={errors?.title?.message}
                      />
                    )}
                  />
                  <Controller
                    name="cleanlog_structure"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CleanLog
                        {...field}
                        saveCleanLog={(selectedItems: any) =>
                          setValue('cleanlog_structure', selectedItems)
                        }
                      />
                    )}
                  />
                  <Controller
                    name="rule"
                    control={control}
                    defaultValue={template?.rules}
                    render={({ field }) => (
                      <InputText
                        fullWidth
                        variant="outlined"
                        error={!!errors?.rule}
                        size="small"
                        label={t('addListing.ruleLabel')}
                        {...register('rule', {
                          required: t('addListing.required') as string,
                        })}
                        {...field}
                        placeholder={template?.rules}
                        multiline
                        minRows={2}
                        helperText={errors?.rule?.message}
                      />
                    )}
                  />
                  <Controller
                    name="instructions"
                    control={control}
                    defaultValue={formattedDescription}
                    render={({ field }) => (
                      <InputText
                        fullWidth
                        variant="outlined"
                        error={!!errors?.instructions}
                        size="small"
                        label={t('addListing.instruction')}
                        {...register('instructions', {
                          required: t('addListing.required') as string,
                        })}
                        {...field}
                        placeholder={formattedDescription}
                        multiline
                        minRows={2}
                        helperText={errors?.instructions?.message}
                      />
                    )}
                  />
                  {!hasLimitation(LimitationsEnum.LISTING_PRICE) && (
                    <div className={'flex items-center justify-end mr-[20px]'}>
                      <button
                        onClick={() =>
                          navigate('/business-owner/settings', {
                            state: { tab: 1 },
                          })
                        }
                        className={classes.SubscribeText}
                      >
                        Subscribe to basic monetization to enable this feature.
                      </button>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Grid
                      item
                      xs={4}
                      sm={2}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: UIConstants.primaryBgColor,
                      }}
                    >
                      {t('addListing.priceLabel')}
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sm={10}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                      }}
                    >
                      {!hasLimitation(LimitationsEnum.LISTING_PRICE) ? (
                        <Tooltip
                          title={
                            <Typography variant="subtitle2">
                              Rent your bathroom now. Subscribe to{' '}
                              <a
                                style={{ color: 'white' }}
                                href={process.env.REACT_APP_BASICMONETIZE_URL}
                              >
                                Tsekmo Basic Monetization Package.
                              </a>
                            </Typography>
                          }
                        >
                          <div style={{ display: 'flex' }}>
                            <IncDecCounter
                              disabled={true}
                              control={control}
                              size="small"
                              name="price"
                              defaultValue={0.0}
                              step={0.99}
                              min={0.0}
                              max={9.99}
                              setValue={setValue}
                              register={register}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <div style={{ display: 'flex', color: 'green' }}>
                          <IncDecCounter
                            disabled={false}
                            control={control}
                            size="small"
                            name="price"
                            defaultValue={0.0}
                            step={0.99}
                            min={0.0}
                            max={4.99}
                            setValue={setValue}
                            register={register}
                          />
                        </div>
                      )}
                    </Grid>
                  </div>
                  <div className="flex">
                    <div onClick={handleHours} className={classes.openHourText}>
                      {t('addListing.openHours')}
                    </div>
                    <span className={classes.hours}>
                      {timeDifference !== '' &&
                        ` - ${timeDifference} ${t('hours')}`}
                    </span>
                  </div>
                  <Stack spacing={2} direction={isMobile ? 'column' : 'row'}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Controller
                        name="open_hour"
                        control={control}
                        defaultValue={new Date()}
                        rules={{
                          required: t('addListing.required') as string,
                        }}
                        render={({ field }) => (
                          <MobileTimePicker
                            ampm={true}
                            value={field.value}
                            onChange={(newValue) => {
                              field.onChange(newValue)
                            }}
                            renderInput={(params) => (
                              <FormControl variant="standard">
                                <InputText
                                  {...params}
                                  label={t('addListing.startTime') as string}
                                  id="input-with-icon-textfield"
                                  variant="outlined"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <ClockIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={!!errors?.open_hour}
                                  helperText={errors?.open_hour?.message}
                                />
                              </FormControl>
                            )}
                          />
                        )}
                      />
                      <Controller
                        name="close_hour"
                        control={control}
                        defaultValue={new Date()}
                        rules={{
                          required: t('addListing.required') as string,
                        }}
                        render={({ field }) => (
                          <MobileTimePicker
                            value={field.value}
                            ampm={true}
                            onChange={(newValue) => {
                              field.onChange(newValue)
                            }}
                            renderInput={(params) => (
                              <FormControl variant="standard">
                                <InputText
                                  {...params}
                                  label={t('addListing.endTime') as string}
                                  id="input-with-icon-textfield"
                                  variant="outlined"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <ClockIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={!!errors?.close_hour}
                                  helperText={errors?.close_hour?.message}
                                />
                              </FormControl>
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack spacing={2} direction={isMobile ? 'column' : 'row'}>
                    <Controller
                      name="location"
                      control={control}
                      defaultValue={
                        businessInfo && JSON.parse(businessInfo?.location).label
                      }
                      render={({ field }) => (
                        <LocationSelector
                          {...field}
                          disabled
                          setSelectedLocationData={setSelectedLocationData}
                          label={t('addListing.location')}
                          size="small"
                          error={errors?.location}
                          helperText={errors?.location?.message}
                        />
                      )}
                    />
                    <PhoneInput
                      onlyCountries={countries}
                      fullWidth
                      defaultValue={userData && userData.mobile_number}
                      label={t('addListing.phoneNumberLabel')}
                      variant="outlined"
                      size="small"
                      defaultCountry={'us'}
                      control={control}
                      name={'mobile_number'}
                      error={errors?.mobile_number}
                      helperText={errors?.mobile_number?.message}
                    ></PhoneInput>
                  </Stack>
                  <FormControlLabel
                    control={
                      <Controller
                        name="is_active"
                        control={control}
                        defaultValue={true}
                        render={({ field }) => (
                          <Toggle
                            checked={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    }
                    label={t('addListing.isActiveLabel') as string}
                    labelPlacement="start"
                    className={classes.becomeAHost}
                  />

                  <FormControlLabel
                    control={
                      <Controller
                        name="is_active"
                        control={control}
                        defaultValue={true}
                        render={({ field }) => (
                          <Toggle
                            checked={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    }
                    label="Show the listing in public bathrooms"
                    labelPlacement="start"
                    className={classes.becomeAHost}
                  />

                  <FormControlLabel
                    control={
                      <Controller
                        name="is_valid_id_required"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Toggle
                            checked={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    }
                    label={'Valid photo id is required for bathroom users'}
                    labelPlacement="start"
                    className={classes.becomeAHost}
                  />
                  <p className={classes.noteText}>
                    I agree that I am responsible for validating the photo ID of
                    my bathroom users
                  </p>

                  <Controller
                    name="file_list"
                    control={control}
                    defaultValue={null}
                    rules={
                      listingData?.uploads
                        ? {}
                        : {
                            required: t('addListing.required') as string,
                            validate: {
                              isSelected: (value) => value !== null,
                            },
                          }
                    }
                    render={({ field }) => (
                      <DropUpload
                        text="Add Listing Photo"
                        dragText="Upload Photo"
                        onFileDrop={(event) => addPhoto(event)}
                      />
                    )}
                  />

                  <div className="max-w-full mt-4">
                    <UploadImageList
                      imageFileList={currentFileList}
                      onDelete={handleDeleteImage}
                      columns={1}
                      remoteImageFileList={
                        remoteFileList.length > 0 ? remoteFileList : undefined
                      }
                    />
                  </div>

                  <CustomButton
                    type="submit"
                    variant="contained"
                    disabled={!isValid || !isDirty || loading.creatingListing}
                    fullWidth
                  >
                    {isEditMode ? (
                      t('addListing.update')
                    ) : loading && loading.creatingListing ? (
                      <CircularProgress size={25} />
                    ) : (
                      t('addListing.submit')
                    )}
                  </CustomButton>

                  <CustomButton
                    type="reset"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      onDialogClose()
                    }}
                    fullWidth
                  >
                    {t('addListing.close')}
                  </CustomButton>
                </Stack>
              </div>
            )}
          </form>
        </Paper>
      </div>
    </Dialog>
  )
}
