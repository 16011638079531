import { makeStyles } from '@mui/styles'
import { Avatar, Theme, Menu, MenuItem, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../../assets/images/logo.svg'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import Chat from '@mui/icons-material/Chat'
import { useEffect, useState } from 'react'
import { popoverClasses } from '@mui/material/Popover'
import Logout from '@mui/icons-material/Logout'
import {
  fetchBusinessInfo,
  fetchUser,
  logout,
  switchUserTypeForLogin,
} from '../../../store/app/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import Settings from '@mui/icons-material/Settings'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import GroupsIcon from '@mui/icons-material/Groups'
import UIConstants from '../../../theme/constants/ui-constants'
import { useGetUserType } from '../../composables'
import QrCodeIcon from '@mui/icons-material/QrCode'
import Storefront from '@mui/icons-material/Storefront'
import QRCodePrint from '../../business-owner/Listing/components/QRCodePrint'
import { ApplicationState } from '../../../store'
import { isJson } from '../../../app/utils/common-utils'
import AutorenewIcon from '@mui/icons-material/Autorenew'

const useStyle = makeStyles((theme: Theme) => ({
  headerContainer: {
    borderBottom: '1px solid #E2E8F0',
  },
  menuDrawer: {
    transition: '0.4s',
    [theme.breakpoints.down(1024)]: {
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundColor: UIConstants.primaryBgColor,
      width: '300px',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '5px',
      padding: '20px',
    },
    zIndex: 2,
  },
  pointerEventsBlocker: {
    transition: '0.4s',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(100, 100, 100, 0.5)',
    zIndex: 1,
  },
  hidden: {
    pointerEvents: 'none',
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  menuHidden: {
    [theme.breakpoints.down(1024)]: {
      translate: '-300px',
    },
  },
  menuVisible: {
    [theme.breakpoints.down(1024)]: {
      translate: '0px',
    },
  },
}))

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyle()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [currentHeaders, setCurrentHeaders] = useState<any>([])
  const [currentMenuItems, setCurrentMenuItems] = useState<any>([])
  const open = Boolean(anchorEl)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const { userData } = useSelector((state: ApplicationState) => state.app)
  const currentUser = useSelector(
    (state: ApplicationState) => state.user.userData
  )

  const [showSecureQrCodePrint, setSecureShowQrCodePrint] =
    useState<boolean>(false)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClickBlocker = () => {
    if (window.innerWidth < 1024) {
      setOpenDrawer(false)
    }
  }

  const handleNavigate = (route: string) => {
    if (currentUserType === 'business-owner' || currentUserType === 'admin') {
      navigate(`/business-owner/${route}`)
    } else {
      navigate(`/customer/${route}`)
    }
    handleClose()
  }

  // const handleSwitchLogin = (): void => {
  //   if (currentUserType === 'business-owner' || currentUserType === 'admin') {
  //     const reqData: any = {}
  //     reqData.email = currentUser.email
  //     reqData.mobile_number = currentUser.mobile_number || ""
  //     reqData.user_type = 2
  //     dispatch(switchUserTypeForLogin({ navigate, reqData }))
  //     // navigate(`/landing`);
  //   } else {
  //     const reqData: any = {}
  //     reqData.email = currentUser.email
  //     reqData.mobile_number = currentUser.mobile_number || ""
  //     reqData.user_type = 1
  //     dispatch(switchUserTypeForLogin({ navigate, reqData }))
  //     // navigate(`/landing`);
  //   }
  // }

  const businessHeaders = [
    {
      id: 1,
      title: 'Dashboard',
      link: '/business-owner',
      icon: <HomeOutlinedIcon fontSize="small" />,
    },
    {
      id: 2,
      title: 'Listings',
      link: '/business-owner/listings',
      icon: <ListAltOutlinedIcon fontSize="small" />,
    },
    {
      id: 3,
      title: 'Tsekrs',
      link: '/business-owner/tsekrs',
      icon: <PeopleAltOutlinedIcon fontSize="small" />,
    },
    {
      id: 3,
      title: 'Messages',
      link: '/business-owner/messages',
      icon: <Chat fontSize="small" />,
    }
  ]

  // const customerHeaders = [
  //   {
  //     id: 1,
  //     title: 'Home',
  //     link: '/customer',
  //     icon: <HomeOutlinedIcon fontSize="small" />,
  //   },
  //   {
  //     id: 2,
  //     title: 'Favorites',
  //     link: '/customer/favorites',
  //     icon: <ListAltOutlinedIcon fontSize="small" />,
  //   },
  //   {
  //     id: 3,
  //     title: 'Tsekmo Social',
  //     link: '/customer/tsekmo-social',
  //     icon: <GroupsIcon fontSize="small" />,
  //   },
  // ]

  const businessMenuItems = [
    // {
    //   id: 1,
    //   onClick: () => {
    //     handleNavigate('profile')
    //   },
    //   title:
    //     currentUser &&
    //     `${currentUser.user_info?.first_name} ${currentUser.user_info?.last_name}`,
    //   icon: <Avatar sx={{ width: '20px', height: '20px' }} src={avatarImage} />,
    // },
    {
      id: 2,
      onClick: () => {
        handleNavigate('tsekmo-social')
      },
      title: 'Tsekmo Social',
      icon: <GroupsIcon fontSize="small" />,
    },
    // {
    //   id: 3,
    //   onClick: () => {
    //     navigate('/signup/connect-stripe')
    //   },
    //   title: 'Connect Stripe',
    //   icon: <SubscriptionsIcon fontSize="small" />,
    //   //Running disabled checking here due to react state events. Once this has been declared, state doesn't update when running function from here. Need to refactor
    //   disabled:
    //     (!currentUser.package_subscribed ||
    //       currentUser.package_subscribed !== '') &&
    //     isJson(currentUser.package_subscribed as string)
    //       ? !JSON.parse(currentUser.package_subscribed as string)?.includes(
    //           'anti-vandal'
    //         )
    //       : true,
    //   disabledTooltipMessage:
    //     'Subscribe to anti-vandalism to enable this feature.',
    // },
    // {
    //   id: 4,
    //   onClick: () => {
    //     handleNavigate('bill-list')
    //   },
    //   title: 'Bill List',
    //   icon: <AccountBalanceWalletIcon fontSize="small" />,
    //   disabled:
    //     (!currentUser.package_subscribed ||
    //       currentUser.package_subscribed !== '') &&
    //     isJson(currentUser.package_subscribed as string)
    //       ? !JSON.parse(currentUser.package_subscribed as string)?.includes(
    //           'anti-vandal'
    //         )
    //       : true,
    //   disabledTooltipMessage:
    //     'Subscribe to anti-vandalism to enable this feature.',
    // },
    // {
    //   id: 5,
    //   onClick: () => {
    //     handleNavigate('settings')
    //   },
    //   title: 'Settings',
    //   icon: <Settings fontSize="small" />,
    // },
    {
      id: 6,
      onClick: () => {
        handleQRcode()
      },
      title: 'Store QR Code',
      icon: <QrCodeIcon fontSize="small" />,
    },
    // {
    //   id: 7,
    //   onClick: () => {
    //     handleSwitchLogin()
    //   },
    //   title: 'Switch to Customer',
    //   icon: <AutorenewIcon fontSize="small" />,
    // },

    {
      id: 7,
      onClick: () => {
        handleNavigate('affiliates')
      },
      title: 'Affiliate Marketing',
      icon: <Storefront fontSize="small" />,
    },
    {
      id: 8,
      onClick: () => {
        dispatch(logout())
      },
      title: 'Logout',
      icon: <Logout fontSize="small" />,
    },
  ]

  // const customerMenuItems = [
  //   // {
  //   //   id: 1,
  //   //   onClick: () => {
  //   //     handleNavigate('profile')
  //   //   },
  //   //   title:
  //   //     currentUser &&
  //   //     `${currentUser.user_info?.first_name} ${currentUser.user_info?.last_name}`,
  //   //   icon: <Avatar sx={{ width: '20px', height: '20px' }} />,
  //   // },
  //   {
  //     id: 2,
  //     onClick: () => {
  //       handleNavigate('tsekrs')
  //     },
  //     title: 'Tsekrs',
  //     icon: <PeopleAltOutlinedIcon fontSize="small" />,
  //   },
  //   {
  //     id: 3,
  //     onClick: () => {
  //       handleSwitchLogin()
  //     },
  //     title: 'Switch to Business Owner',
  //     icon: <AutorenewIcon fontSize="small" />,
  //   },
  //   {
  //     id: 4,
  //     onClick: () => {
  //       dispatch(logout())
  //     },
  //     title: 'logout',
  //     icon: <Logout fontSize="small" />,
  //   },
  // ]

  const currentUserType = useGetUserType()

  useEffect(() => {
    if (currentUserType && currentUser) {
      if (currentUserType === 'business-owner' || currentUserType === 'admin') {
        setCurrentHeaders(businessHeaders)
        setCurrentMenuItems(businessMenuItems)
      } 
      // else {
      //   setCurrentHeaders(customerHeaders)
      //   setCurrentMenuItems(customerMenuItems)
      // }
    }
  }, [currentUserType, currentUser])

  useEffect(() => {
    if (userData.id && userData.id !== 0) {
      dispatch(fetchUser({user_id: userData.id, mobile_number: userData?.mobile_number, email: userData?.email}))
      if (currentUserType === 'business-owner' || currentUserType === 'admin') {
        dispatch(fetchBusinessInfo(userData.id))
      }
    }
  }, [userData])

  const handleQRcode = () => {
    setSecureShowQrCodePrint(true)
  }
  return (
    <div
      className={` h-20 w-full flex items-center justify-between px-10 relative ${classes.headerContainer}`}
    >
      <MenuIcon
        className="lg:hidden block"
        onClick={() => setOpenDrawer(true)}
      />
      <div className="flex w-full h-full items-center lg:justify-normal justify-evenly">
        <Link to="/business-owner">
          <img className="lg:w-32 w-36" src={logo} alt="Tsekmo Logo" />
        </Link>
        <div
          className={`lg:h-full lg:flex lg:items-center lg:justify-between lg:ml-12 ${
            classes.menuDrawer
          } ${openDrawer ? classes.menuVisible : classes.menuHidden}`}
        >
          {currentHeaders.map((header: any) => (
            <Link
              key={header.id}
              to={header.link}
              onClick={() => setOpenDrawer(false)}
              className="lg:h-full"
            >
              <div className="lg:h-full flex gap-2 items-center text-sm font-medium rounded-md lg:rounded-none text-white lg:text-mainText hover:bg-[#232e96] active:bg-[#232e96] lg:p-4 p-4 lg:hover:bg-slate-50 hover:cursor-pointer duration-75">
                {header.icon} {header.title}
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div
        className={`${classes.pointerEventsBlocker} ${
          openDrawer ? classes.visible : classes.hidden
        }`}
        onClick={handleClickBlocker}
      ></div>
      <button onClick={handleClick} className="w-fit h-fit">
        {currentUser && currentUser.user_info?.image_url ? (
          <img
            className="w-[36px] h-[36px] rounded-full"
            src={currentUser.user_info?.image_url}
            referrerPolicy="no-referrer"
            alt="user"
          />
        ) : (
          <Avatar sx={{ width: '36px', height: '36px' }} />
        )}
      </button>

      <Menu
        id="basic-menu"
        sx={{
          zIndex: 2,
          [`& .${popoverClasses.paper}`]: {
            position: 'relative',
            width: 'fit-content',
            overflow: 'visible',
            border: '1px solid #E2E8F0',
            color: UIConstants.subText,
            '&::before': {
              content: "''",
              height: '10px',
              width: '10px',
              right: 0,
              marginRight: '15px',
              marginTop: '-6px',
              rotate: '45deg',
              backgroundColor: 'white',
              position: 'absolute',
              borderTop: '1px solid #E2E8F0',
              borderLeft: '1px solid #E2EBF0',
            },
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: 10, marginLeft: 20 }}
      >
        <MenuItem
          onClick={() => handleNavigate('profile')}
          sx={{ display: 'flex', gap: '10px' }}
        >
          {currentUser && currentUser.user_info?.image_url ? (
            <img
              className="w-[24px] h-[24px] rounded-full"
              src={currentUser.user_info?.image_url}
              referrerPolicy="no-referrer"
              alt="user"
            />
          ) : (
            <Avatar sx={{ width: '24px', height: '24px' }} />
          )}
          {currentUser &&
            `${currentUser.user_info?.first_name} ${currentUser.user_info?.last_name}`}
        </MenuItem>
        {currentMenuItems.map((menuItem: any) => (
          <Tooltip
            key={menuItem.id}
            title={
              menuItem?.disabled ? (
                <button
                  onClick={() =>
                    navigate('/business-owner/settings', {
                      state: { tab: 1 },
                    })
                  }
                >
                  {menuItem.disabledTooltipMessage}
                </button>
              ) : (
                ''
              )
            }
            placement="left"
            arrow
          >
            <div>
              <MenuItem
                onClick={menuItem.onClick}
                disabled={menuItem.disabled}
                sx={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                {menuItem.icon} {menuItem.title}
              </MenuItem>
            </div>
          </Tooltip>
        ))}
      </Menu>
      <QRCodePrint
        open={showSecureQrCodePrint}
        handleClose={() => setSecureShowQrCodePrint(false)}
        qr_code={'&secure=true'}
        secureCode={true}
      />
    </div>
  )
}

export default Header
