import { action } from 'typesafe-actions'
import {
  CheckoutRequestModel,
  DeleteListingReqModel,
  FavoritesReqModel,
  ListingActionTypes,
  ListingModel,
  ListingsModel,
  ListingType,
  ListingUpdateModel,
  SearchListingReqModel,
  TransactionRequestModel,
  UpdateStatusReqModel,
  ListingPostModel,
  CleanRequestModel,
  CleanGetModel,
  SearchListingsReqModel,
  CleanLogStructureModel,
  SelectedListingRequestModel,
} from './types'
import { UserData } from '../user/types'

export const createListing = (model: any) =>
  action(ListingActionTypes.CREATE_LISTING_DATA, model)

export const fetchListingUserData = (user_id: number) =>
  action(ListingActionTypes.FETCH_LISTING_USER_DATA, user_id)

export const setListingUserData = (userData: UserData) =>
  action(ListingActionTypes.SET_LISTING_USER_DATA, userData)

export const setCreatingListingStatus = (status: boolean) =>
  action(ListingActionTypes.SET_CREATING_LISTING_STATUS, status)

export const createOrUpdateCleanlogStructure = (
  model: CleanLogStructureModel
) => action(ListingActionTypes.CREATE_OR_UPDATE_CLEANLOG_STRUCTURE, model)

export const fetchCleanlogStructure = (user_id: number) =>
  action(ListingActionTypes.FETCH_CLEANLOG_STRUCTURE, { user_id: user_id })

export const setCleanLogStructure = (cleanLogStructure: any) =>
  action(ListingActionTypes.SET_CLEANLOG_STRUCTURE, cleanLogStructure)

export const updateListing = (model: FormData) =>
  action(ListingActionTypes.EDIT_LISTING, model)

export const fetchListings = (user_id: number) =>
  action(ListingActionTypes.FETCH_LISTING_DATA, user_id)

export const fetchAllListingsForCustomer = (model: SearchListingReqModel) =>
  action(ListingActionTypes.FETCH_ALL_LISTINGS_FOR_CUSTOMER, model)

export const fetchAllListings = (model: SearchListingsReqModel) =>
  action(ListingActionTypes.FETCH_ALL_LISTINGS, model)
export const fetchOthersListings = (user_id: number) =>
  action(ListingActionTypes.FETCH_OTHERS_LISTING, user_id)

export const fetchListingTypes = () =>
  action(ListingActionTypes.FETCH_LISTING_TYPES)

export const setListingTypes = (listing_types: ListingType[]) =>
  action(ListingActionTypes.SET_LISTING_TYPES, listing_types)

export const setOthersListingData = (listings: ListingModel[]) =>
  action(ListingActionTypes.SET_OTHERS_LISITNGS_DATA, listings)

export const setCleanLogData = (cleanLog: any) =>
  action(ListingActionTypes.SET_CLEAN_LOG_DATA, cleanLog)

export const upadateListingData = (listings: ListingUpdateModel) =>
  action(ListingActionTypes.UPDATE_LISTING_DATA, listings)

export const updateListingStatus = (model: UpdateStatusReqModel) =>
  action(ListingActionTypes.UPDATE_ACTIVE_STATUS, model)

export const setFavoritesStatus = (model: FavoritesReqModel) =>
  action(ListingActionTypes.SET_FAV_STATUS, model)

export const deleteLisitng = (listing_id: number) =>
  action(ListingActionTypes.DELETE_LISTING, listing_id)

export const checkoutListing = (model: CheckoutRequestModel) =>
  action(ListingActionTypes.CHECKOUT_LISTING, model)

export const createTransaction = (model: TransactionRequestModel) =>
  action(ListingActionTypes.CREATE_TRANSACTION, model)

export const setTransactionData = (transactionData: any) =>
  action(ListingActionTypes.SET_TRANSACTION_DATA, transactionData)

export const setLoadingPayment = (status: boolean) =>
  action(ListingActionTypes.SET_LOADING_PAYMENT_DATA, status)

export const createCleanLog = (model: CleanRequestModel) =>
  action(ListingActionTypes.CREATE_CLEAN_LOG, model)

export const fetchCleanLog = (model: any) =>
  action(ListingActionTypes.FETCH_CLEAN_LOG, model)

export const setCleanLogLoading = (status: boolean) =>
  action(ListingActionTypes.SET_CLEAN_LOG_LOADING, status)

export const fetchSelectedListing = (model: SelectedListingRequestModel) =>
  action(ListingActionTypes.FETCH_SELECTED_LISTING, model)

export const setSelectedListing = (model: ListingModel) =>
  action(ListingActionTypes.SET_SELECTED_LISTING, model)

export const updateSelectedListingField = (
  fieldName: string,
  fieldValue: any
) => {
  return {
    type: 'UPDATE_SELECTED_LISTING_FIELD',
    payload: { fieldName, fieldValue },
  }
}

export const isListUpdated = (isUpdated: boolean) =>
  action(ListingActionTypes.IS_LISTING_UPDATED, isUpdated)

export const fetchAllFavoritesListingsForCustomer = (
  model: SearchListingReqModel
) => action(ListingActionTypes.FETCH_ALL_FAV_LISTINGS_FOR_CUSTOMER, model)

export const fetchListingPost = (user_id: number, post_id = 0) =>
  action(ListingActionTypes.FETCH_LISTING_POST, { user_id, post_id })

export const uploadPhotoId = (model: FormData) =>
  action(ListingActionTypes.UPLOAD_ID, model)

export const SetValidationCompleteStatus = (isComplete: boolean) =>
  action(ListingActionTypes.IS_VALIDATION_COMPLETE, isComplete)

export const setListingPost = (listing_Post: ListingPostModel[]) =>
  action(ListingActionTypes.SET_LISTING_POST, listing_Post)
export const setListingData = ({
  active_count,
  listings,
}: {
  active_count: number
  listings: ListingModel[]
}) =>
  action(ListingActionTypes.SET_LISITNGS_DATA, {
    active_count,
    listings,
  })

export const setListingsData = ({
  active_count,
  listings,
}: {
  active_count: number
  listings: ListingsModel[]
}) =>
  action(ListingActionTypes.SET_ALL_LISITNGS_DATA, {
    active_count,
    listings,
  })

export const verifyIDRequest = (model: any) =>
  action(ListingActionTypes.VERIFY_ID_REQUEST, model)

export const resolveBrokenItems = (model: any) =>
  action(ListingActionTypes.RESOLVE_BROKEN_ITEMS, model)

export const editIncidentReportByBo = (model: any) =>
  action(ListingActionTypes.EDIT_INCIDENT_REPORT_BY_BO, model)

export const fetchTemplate = (model : any) =>
  action(ListingActionTypes.GET_TEMPLATE, model)

export const setTemplate = (template: any) => 
  action(ListingActionTypes.SET_TEMPLATE, template)