import { Route, Routes } from 'react-router-dom'
import { AuthGuard } from '../../app/components/AuthGuard'
import Dashboard from './Dashboard/views/Dashboard'
import Listing from './Listing/views/Listing'
import Tsekrs from '../common/Tsekrs/views/Tsekrs'
import Settings from './Settings/views/Settings'
import Profile from '../common/Profile/views/Profile'
import BillList from './Bill-List/views/BillList'
import TsekmoSocial from '../common/Tsekmo-Social/views/TsekmoSocial'
import ListingHome from '../common/Listing/views/ListingHome'
import PricingTable from './Settings/components/PricingTable'
import Affiliates from './Affiliates/views'
import Messages from './Messages/views'

function BusinessOwnerRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        }
      />
      <Route
        path="/listings"
        element={
          <AuthGuard>
            <Listing/>
          </AuthGuard>
        }
      />
      <Route
        path="/tsekrs"
        element={
          <AuthGuard>
            <Tsekrs />
          </AuthGuard>
        }
      />
      <Route
        path="/settings"
        element={
          <AuthGuard>
            <Settings />
          </AuthGuard>
        }
      />
      <Route
        path="/profile"
        element={
          <AuthGuard>
            <Profile />
          </AuthGuard>
        }
      />
      <Route
        path="/bill-list"
        element={
          <AuthGuard>
            <BillList />
          </AuthGuard>
        }
      />
      <Route
        path="/tsekmo-social"
        element={
          <AuthGuard>
            <TsekmoSocial />
          </AuthGuard>
        }
      />
      <Route
        path="/listing/:id"
        element={
          <AuthGuard>
            <ListingHome/>
          </AuthGuard>
        }
      />
      <Route
        path="pricing-table"
        element={
          <AuthGuard>
            <PricingTable/>
          </AuthGuard>
        }
      />
      <Route
        path="affiliates"
        element={
          <AuthGuard>
            <Affiliates/>
          </AuthGuard>
        }
      />
      <Route
        path="messages"
        element={
          <AuthGuard>
            <Messages/>
          </AuthGuard>
        }
      />
      

      {/* <Route
        path="/listing-reviews/:id"
        element={
          <AuthGuard>
            <ViewAllReviews isCustomer={false} />
          </AuthGuard>
        }
      />
      <Route
        path="/community"
        element={
          <AuthGuard>
            <CommunityHome></CommunityHome>
          </AuthGuard>
        }
      />

      <Route
        path="/tsekmo-community"
        element={
          <AuthGuard>
            <TsekmoCommunityHome></TsekmoCommunityHome>
          </AuthGuard>
        }
      />

      <Route
        path="/bill-list"
        element={
          <AuthGuard>
            <Billist></Billist>
          </AuthGuard>
        }
      />

      <Route
        path="/community-post"
        element={
          <AuthGuard>
            <CommunityPostHome></CommunityPostHome>
          </AuthGuard>
        }
      />

      <Route
        path="/community-report"
        element={
          <AuthGuard>
            <CommunityReportHome></CommunityReportHome>
          </AuthGuard>
        }
      />

      <Route
        path="/profile"
        element={
          <AuthGuard>
            <ProfileHome></ProfileHome>
          </AuthGuard>
        }
      />

      <Route
        path="/community-view"
        element={
          <AuthGuard>
            <CommunityPostView />
          </AuthGuard>
        }
      />
      <Route
        path="/help-center"
        element={
          <AuthGuard>
            <HelpCenter isLogedIn={true} />
          </AuthGuard>
        }
      />
      <Route
        path="/terms-and-condition"
        element={
          <AuthGuard>
            <TermsAndCondition isLogedIn={true} />
          </AuthGuard>
        }
      />
      <Route
        path="/cancelation-option"
        element={
          <AuthGuard>
            <CancelationOption isLogedIn={true} />
          </AuthGuard>
        }
      />
      <Route
        path="/gdpr"
        element={
          <AuthGuard>
            <Gdpr isLogedIn={true} />
          </AuthGuard>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <AuthGuard>
            <PrivacyPolicy isLogedIn={true} />
          </AuthGuard>
        }
      />
      <Route
        path="/about-us"
        element={
          <AuthGuard>
            <AboutUs isLogedIn={true} />
          </AuthGuard>
        }
      />
      <Route
        path="/contact-us"
        element={
          <AuthGuard>
            <ContactUs isLogedIn={true} />
          </AuthGuard>
        }
      /> */}
      <Route
        path="/*"
        element={
          <div className="text-mainText text-3xl grid place-items-center w-full h-full -mt-20">
            404 Page Not Found
          </div>
        }
      />
    </Routes>
  )
}
export default BusinessOwnerRoutes
