import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { useSocket } from "../../../business-owner/Settings/views/SocketContext";
import { useDispatch, useSelector } from "react-redux";
import { clearSpecificChatData, getSpecificMessage, retrieveUserChat, getChatroomMessage } from "../../../../store/app/user/actions";
import { ApplicationState } from "../../../../store";
import { makeStyles } from "@mui/styles";
import UIConstants from "../../../../theme/constants/ui-constants";
import { collection, getDocs, orderBy, limit, startAfter, onSnapshot, query, getFirestore, addDoc } from "firebase/firestore";


const useStyles = makeStyles((theme) => ({
    messageContainer: {
      display: "flex",
      flexDirection: "column-reverse",
      overflowY: "auto",
      padding: "10px",
      boxSizing: "border-box",
    },
    messageBubble: {
      maxWidth: "70%", // Adjust the bubble width dynamically
      width: "auto", // Allow the bubble width to expand with the text
      padding: "8px 12px",
      borderRadius: "12px",
      wordWrap: "break-word", // Ensure text wraps properly
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: "10px",
    },
    senderMessage: {
      backgroundColor: UIConstants.senderBubbleColor,
      color: "white",
      marginLeft: "auto", // Push sender's message to the right
    },
    recipientMessage: {
      backgroundColor: UIConstants.recipientBubbleColor,
      color: UIConstants.mainText,
      marginRight: "auto", // Push recipient's message to the left
    },
  }));

const Messages: React.FC = () => {
  const db = getFirestore()
  const classes = useStyles();
  const [selectedConversation, setSelectedConversation] = useState<any | null>(null);
  const [newMessage, setNewMessage] = useState("");
  const { socket, isConnected } = useSocket();
  const [specificChatDatum, setSpecificChatDatum] = useState<any | null>(null);
  const [lastVisible, setLastVisible] = useState<any | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const { userData, chatData, specificChatData, specificChatRoomData } = useSelector(
    (state: ApplicationState) => state.user
  );
  const dispatch = useDispatch();
  const PAGE_SIZE = 10; // Number of messages per load

  // Load messages for the selected conversation
  const handleConversationClick = (chat: any) => {
    dispatch(clearSpecificChatData())
    setSelectedConversation(chat);
    // !Let's not use the getSpecificMessage endpoint for now as we will going to use the firestore snapshot directly
    // dispatch(
    //     getSpecificMessage({
    //       senderId: userData.id,
    //       recipientId:
    //         chat.senderId === userData.id
    //           ? chat.senderId
    //           : chat.recipientId,
    //       page: 1,   // Start with the first page
    //     })
    //   );
  };

  useEffect(() => {
    console.log('Setting up!')
    let unsubscribe: (() => void) | null = null;
  
    if (selectedConversation) {
      const chatRoomId = selectedConversation.chatRoomId;
  
      const messagesRef = collection(db, "chats", chatRoomId, "messages");
      const messagesQuery = query(messagesRef, orderBy("timestamp", "desc"), limit(PAGE_SIZE));
  
      unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const newMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSpecificChatDatum(newMessages);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(newMessages.length === PAGE_SIZE); // If less than PAGE_SIZE, no more data
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [selectedConversation, dispatch, db]);

  // Load more messages
  const loadMoreMessages = async () => {
    if (lastVisible && selectedConversation) {
      const chatRoomId = selectedConversation.chatRoomId;
      const messagesRef = collection(db, "chats", chatRoomId, "messages");
      const nextQuery = query(
        messagesRef,
        orderBy("timestamp", "desc"),
        startAfter(lastVisible),
        limit(PAGE_SIZE)
      );

      const snapshot = await getDocs(nextQuery);
      const newMessages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      setSpecificChatDatum((prev: any) => [...prev, ...newMessages]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(newMessages.length === PAGE_SIZE);
    }
  };

  useEffect(() => {
    if (socket && isConnected) {
      const handleNewMessage = (data: any) => {
        if (userData.id) {
            dispatch(retrieveUserChat(userData.id));
        }
        console.log("Received newChatMessage event here:", data);
      };

      socket.on("newChatMessage", handleNewMessage);

      return () => {
        socket.off("newChatMessage", handleNewMessage);
      };
    }
  }, [socket, isConnected, dispatch, userData]);

  useEffect(() => {
    if (userData.id) {
      dispatch(retrieveUserChat(userData.id));
    }
  }, [dispatch, userData]);

  //TODO: This will be move to backend for better security and handling of firestore listener
  const handleSendMessage = async () => {
    if (newMessage.trim() && selectedConversation) {
      const chatRoomId = selectedConversation.chatRoomId;
      const newMessageData = {
        message: newMessage.trim(),
        sender_id: `${userData.id}`,
        recipient_id:
          selectedConversation.senderId === `${userData.id}`
            ? selectedConversation.recipientId
            : selectedConversation.senderId,
        timestamp: new Date(), 
      };
  
      try {
        const messagesCollectionRef = collection(
          db,
          "chats",
          chatRoomId,
          "messages"
        ); 
        await addDoc(messagesCollectionRef, newMessageData); 
        console.log("Message sent successfully");
        dispatch(getChatroomMessage(chatRoomId));
        setNewMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  return (
    <Box display="flex" height="100vh">
      {/* Left Panel: Conversation List */}
      <Box
        sx={{
          width: "30%",
          bgcolor: "background.paper",
          overflowY: "auto",
          borderRight: "1px solid #ccc",
        }}
      >
        <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #ccc" }}>
          Conversations
        </Typography>
        <List>
          {chatData && chatData.map((chat: { 
                senderId: number | undefined;
                recipientId: number | undefined;
                recipient_username: any; 
                sender_username: any; 
                recipient_profile_picture: any; 
                sender_profile_picture: any;
                store_name: string;
                recentMessage: {
                    timestamp: { 
                        _seconds: number;
                        _nano_seconds: number;
                    }; 
                    message: string;
                    sender_id: string;
                    recipient_id: string;
                };
                id: React.Key | null | undefined; 
            }) => {
            const isUserSender = chat.senderId === userData.id;
            const chatName = isUserSender
              ? chat.recipient_username
              : chat.sender_username;
            const chatAvatar = isUserSender
              ? chat.recipient_profile_picture
              : chat.sender_profile_picture;
            const lastMessageTime = new Date(
              chat.recentMessage.timestamp._seconds * 1000
            ).toLocaleString();

            return (
              <ListItem
                key={chat.id}
                onClick={() => handleConversationClick(chat)}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedConversation?.id === chat.id
                      ? "action.selected"
                      : "inherit",
                  "&:hover": {
                    backgroundColor: "action.hover",
                  },
                }}
              >
                <ListItemAvatar>
                  <Avatar src={chatAvatar || undefined} />
                </ListItemAvatar>
                <ListItemText
                  primary={chatName}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        { chat.recentMessage.message || 'No recent message available'}
                      </Typography>
                      <br />
                      <Typography component="span" variant="caption">
                        {lastMessageTime}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Box>

      {/* Right Panel: Chat Window */}
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {selectedConversation ? (
          <>
            <Box sx={{ flex: 1, p: 2, overflowY: "auto" }}>
              <Typography variant="h5" gutterBottom>
                {selectedConversation.senderId === userData.id
                  ? selectedConversation.recipient_username
                  : selectedConversation.sender_username}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Button
                    disabled={!hasMore}
                    onClick={loadMoreMessages}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "0 auto",
                        backgroundColor: "primary.main",
                        color: "black",
                        "&:hover": {
                        backgroundColor: "primary.dark",
                        color: "white",
                        },
                    }}
                    variant="contained"
                    color="secondary"
                >
                    {hasMore ? "Load More" : "No More Messages"}
                </Button>
              <div className={classes.messageContainer}>
                {specificChatDatum?.length > 0 ? (
                        specificChatDatum.map((message: any, index: number) => {
                            const isSender = message.sender_id === `${userData.id}`;
                            return (
                            <div
                                key={index}
                                className={`${classes.messageBubble} ${
                                isSender ? classes.senderMessage : classes.recipientMessage
                                }`}
                                style={{
                                alignSelf: isSender ? "flex-end" : "flex-start",
                                }}
                            >
                                {message.message}
                            </div>
                            );
                        })
                    // }
                    // </>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No messages yet.
                  </Typography>
                )}
              </div>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 2,
                borderTop: "1px solid #ccc",
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Type a message..."
                fullWidth
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                sx={{ mr: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendMessage}
                sx={{
                  backgroundColor: "primary.main",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                    color: "white",
                  },
                }}
              >
                Send
              </Button>
            </Box>
          </>
        ) : (
          <Typography
            variant="h6"
            sx={{ p: 2, textAlign: "center", color: "gray" }}
          >
            Select a conversation to start chatting.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Messages;