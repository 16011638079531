import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack'
import React from 'react'

let useSnackbarRef: WithSnackbarProps
export const Snackbarconfig: React.FC = () => {
    useSnackbarRef = useSnackbar()
    return null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    success(msg: string) {
        this.toast(msg, 'success')
    },
    warning(msg: string) {
        this.toast(msg, 'warning')
    },
    info(msg: string) {
        this.toast(msg, 'info')
    },
    error(msg: string) {
        this.toast(msg, 'error')
    },
    toast(msg: string, variant: VariantType = 'default') {
        useSnackbarRef.enqueueSnackbar(msg, { variant })
    },
    message(msg: string, variant: VariantType = 'default', autoHideDuration: number= 6000, anchorOrigin: any) {
        useSnackbarRef.enqueueSnackbar(msg, { variant, autoHideDuration, anchorOrigin})
    }
}


