export interface LogItem {
    id: number
    disabled: boolean
    text: string
    checked: boolean
    broken?: boolean
    empty?: boolean
    comment: string
    logTime: Date | null
    category: string
}
  
export interface IFormInput {
    listing_type: number
    listing_post: number
    title: string
    cleanlog_structure: string
    rule: string
    price: number
    preferred_price: string
    free_use: boolean
    open_hour: Date
    close_hour: Date
    location: string
    mobile_number: string
    is_active: boolean
    is_valid_id_required: boolean
    file_list: FileList | null
    instructions: string
}

export enum LimitationsEnum {
    LISTING_PRICE = 'LISTING_PRICE',
    CHAT = 'CHAT',
    DISCOUNT = 'DISCOUNT',
    BILL_NOW = 'BILL_NOW',
    BILL_LIST = 'BILL_LIST',
    RESOLVE_ISSUE = 'RESOLVE_ISSUE',
    BLOCK_CUSTOMER = 'BLOCK_CUSTOMER',
  }