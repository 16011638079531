import { UserData } from './user/types';

export enum AppActionTypes {
	SET_IS_AUTHENTICTED = '@@app/SET_IS_AUTHENTICTED',
	SET_AUTHENTICATION_DATA = '@@app/SET_AUTHENTICATION_DATA',
	CLEAR_AUTHENTICATION_DATA = '@@app/CLEAR_AUTHENTICATION_DATA',
	TOGGLEDRAWERSTATE = '@@app/TOGGLEDRAWERSTATE',
	INIT = '@@app/INIT'
}

export interface AuthData {
	isAuthenticated?: boolean;
	token?: string;
	keepLoggedIn?: boolean;
	country_code: string;
}

export interface AppState {
	readonly authData: AuthData;
	readonly userData: UserData;
	readonly language?: string;
	readonly drawerState?: boolean;
}
