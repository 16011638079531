import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import LinkIcon from "@mui/icons-material/Link";
import {getAffiliate} from '../../../../store/app/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'

// Define the type for the affiliate data
interface Affiliate {
  id: string;
  firstname: string;
  lastname: string;
  username: string;
  stripe_account_connected: string;
  tsekmo_packages_subscribe: string;
}

const Affiliates: React.FC = () => {

  const [affiliates, setAffiliates] = useState<Affiliate[]>([]);
  const dispatch = useDispatch()
  const { affiliateData, userData } = useSelector(
    (state: ApplicationState) => state.user
  )

useEffect(() => {
    if(userData) {
        if(userData.user_info) {
            console.log(`Check userData: ${JSON.stringify(userData.user_info.affiliate_code, null, 2)}`)
            dispatch(getAffiliate(userData.user_info.affiliate_code))
        }
    }
}, [userData, dispatch]);

  useEffect(() => {
    setAffiliates(affiliateData);
    console.log(`Check affiliateData : ${JSON.stringify(affiliateData, null, 2)}`)
  }, [affiliateData]);

  const handleShareClick = (link: string): void => {
    // Function to handle sharing the affiliate link
    navigator.clipboard.writeText(link);
    alert("Affiliate link copied to clipboard!");
  };

  return (
    <Box sx={{padding: "20px"}}>
      <Typography variant="h4" gutterBottom>
        Affiliates Dashboard
      </Typography>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Affiliate Name</TableCell>
                <TableCell>Affiliate Username</TableCell>
                <TableCell>Stripe Connected</TableCell>
                <TableCell>Package Subscribe</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {affiliates.length > 0 &&  affiliates.map((affiliate) => (
                <TableRow key={affiliate.id}>
                  <TableCell>{affiliate.firstname} {affiliate.lastname}</TableCell>
                  {/* <TableCell>{affiliate.lastname}</TableCell> */}
                  <TableCell>{affiliate.username}</TableCell>
                  <TableCell>{affiliate.stripe_account_connected}</TableCell>
                  <TableCell>{affiliate.tsekmo_packages_subscribe}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleShareClick(affiliate.firstname)}
                    >
                      <ShareIcon />
                    </IconButton>
                    <IconButton color="secondary">
                      <LinkIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Box p={2} display="flex" justifyContent="flex-end">
          <Button 
          variant="contained" 
          color="primary" 
          sx={{
                textTransform: "none", // Optional: Prevents the text from being uppercased
                color: "black", // Ensure the text color is white when not hovered
                "&:hover": {
                backgroundColor: "#0069d9", // Darker blue when hovered (you can customize this)
                color: "white", // Keep text color white on hover
                },
          }}>
            Add New Affiliate
          </Button>
        </Box> */}
      </Card>
    </Box>
  );
};

export default Affiliates;