import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

// Define styles
const useStyles = makeStyles({
  loaderContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark semi-transparent background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999, // Ensure it stays above other elements
  },
  loader: {
    color: "#ffffff", // White loader color
  },
});

function FullPageLoader () {
  const classes = useStyles();

  return (
    <div className={classes.loaderContainer}>
      <CircularProgress className={classes.loader} size={60} />
    </div>
  );
};

export default FullPageLoader