/**
 * TOSHIBA
 * Copyright(c) TOSHIBA 2021 All Rights Reserved.
 *
 * File Name	: LayoutContainer.tsx
 * Package	: containers
 * Author	: Sajith Rajan
 * Created At	: 16-11-2021
 * Purpose	: ApiList Container.
 *
 * [Change history]
 *
 * Modified By	:
 * Date		:
 * Description	:
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { setAuthenticationData } from '../../store/app/actions';
import { AppState, AuthData } from '../../store/app/types';
import * as signUpActions from '../../store/app/user/actions';
import { 
  CreateActionModel, 
  SaveUserInfoActionModel, 
  StripeConnectRequestModel, 
  SubscribeRequestModel, 
  UserUpdateModel, 
  UserData, 
  UserState ,
  SaveBusinessInfoActionModel,
  SaveBusinessActionModel
} from '../../store/app/user/types';

// Redux-specific props.
export interface SignUpContainerProps extends UserState, AppState {
  setUserData: (req: UserData) => void;
  updateUserData: (req: UserUpdateModel) => void;
  // getUserData: () => void;
  setValidUser: (isValidUser: boolean) => void;
  createUser: (req: CreateActionModel) => void;
  saveUserInfo: (req: SaveUserInfoActionModel) => void;
  createStripeCustomer: (model: { user_id: number, navigate: Function }) => void;
  checkoutSubscription: (model: SubscribeRequestModel) => void;
  connectStripe: (model: any) => void;
  setAuthData: (model: AuthData) => void;
  saveBusinessInfo: (req: SaveBusinessInfoActionModel) => void;
  saveBusiness: (req: SaveBusinessActionModel) => void;
  updateZipEligibilityStatus: ( isZipCodeEligible: boolean ) =>  void
  // updateSignupFormFlag: ( isSignupForm: boolean ) =>  void
}

// Wrapper props for render/children callback.
interface SignUpContainerRenderProps {
  render?: (props: SignUpContainerProps) => React.ReactElement;
  children?: (props: SignUpContainerProps) => React.ReactElement;
}

export const SignUpContainer: React.FC<SignUpContainerRenderProps> = ({
  render,
  children
}) => {
  // We can use Hooks to call in our selector/dispatch functions.
  const {
    userData,
    isValidUser,
    loading,
    stripeDetails,
    flagDetails,
    isConatctInfoUpdatedSucess,
    loginCheckUserTypeData,
    isZipCodeEligible,
    businessTypes,
    // isSignupForm
  } = useSelector((state: ApplicationState) => state.user);

  const {
    authData,
  } = useSelector((state: ApplicationState) => state.app);

  const dispatch = useDispatch();

  // const getUserData = () => dispatch(signUpActions.getUserData);

  const setUserData = (req: UserData) => {
    dispatch(signUpActions.setUserData(req));
  };

  const setAuthData = (req: AuthData) => {
    dispatch(setAuthenticationData(req));
  };

  const connectStripe = (req: StripeConnectRequestModel) => {
    dispatch(signUpActions.connectStripe(req));
  }

  const createStripeCustomer = (model: { user_id: number, navigate: Function }) => {
    dispatch(signUpActions.createStripeCustomer(model));
  };

  const createUser = (req: CreateActionModel) => {
    dispatch(signUpActions.createUser(req));
  };

  const checkoutSubscription = (req: SubscribeRequestModel) => {
    dispatch(signUpActions.checkoutSubscription(req));
  };


  const saveUserInfo = (req: any) => {
    dispatch(signUpActions.saveUserInfo(req));
  };

  const updateUserData = (req: UserUpdateModel) => {
    dispatch(signUpActions.updateUserData(req));
  };

  const setValidUser = (isValidUser: boolean) => {
    dispatch(signUpActions.setValidUser(isValidUser));
  }

  const saveBusinessInfo = (req: SaveBusinessInfoActionModel) => {
    dispatch(signUpActions.saveBusinessInfo(req));
  };

  const  updateZipEligibilityStatus= (req: boolean) => {
    dispatch(signUpActions.updateZipEligibilityStatus(req));
  };

  const saveBusiness = (req: SaveBusinessActionModel) => {
    dispatch(signUpActions.saveBusiness(req));
  }

  // const  updateSignupFormFlag= (req: boolean) => {
  //   dispatch(signUpActions.updateSignupFormFlag(req));
  // };

  const containerProps: SignUpContainerProps = {
    userData,
    loading,
    isValidUser,
    stripeDetails,
    authData,
    flagDetails,
    isConatctInfoUpdatedSucess,
    loginCheckUserTypeData,
    isZipCodeEligible,
    // isSignupForm,
    businessTypes,
    // loginData,
    // getUserData,
    setUserData,
    updateUserData,
    setValidUser,
    createUser,
    saveUserInfo,
    createStripeCustomer,
    checkoutSubscription,
    connectStripe,
    setAuthData,
    saveBusinessInfo,
    saveBusiness,
    updateZipEligibilityStatus
    // updateSignupFormFlag
  };

  // Create a render/children props wrapper with the above variables set as a callback.
  if (render) {
    return render(containerProps);
  }

  if (children) {
    return children(containerProps);
  }

  return null;
};
